.theme_yakchat_going {
  --text-color:  var(--surface);
  --secondary-text-color: var(--background);
  --background-color: var(--primary);
  --right: -4.2rem;
  --border-left: 0.9rem solid var(--background-color);
  --border-radius-left: 55rem 45rem;
  --border-radius-leftT: 1rem 25rem;
  --rotate: rotate(-20deg);
}
.theme_yakchat_coming {
  --text-color: var(--secondary-text);
  --secondary-text-color: var(--onsurfacehigh-text);
  --background-color: var(--onsurfacelowest-text);
  --left: -4.03rem;
  --border-right: 0.9rem solid var(--background-color);
  --border-radius-right: 19rem 20rem;
  --rotate: rotate(20deg);
}

.theme_yakchat_going_setupDark{
  --text-color:  var(--onPrimary);
  --secondary-text-color: var(--onPrimary);
  --background-color: var(--primary);
  --right: -4.2rem;
  --border-left: 0.9rem solid var(--background-color);
  --border-radius-left: 55rem 45rem;
  --border-radius-leftT: 1rem 25rem;
  --rotate: rotate(-20deg);
}

.theme_yakchat_going_setupMobex{
  --text-color:  var(--onPrimary);
  --secondary-text-color: var(--onPrimary) !important;
  --background-color: var(--primary);
  --right: -4.2rem;
  --border-left: 0.9rem solid var(--background-color);
  --border-radius-left: 55rem 45rem;
  --border-radius-leftT: 1rem 25rem;
  --rotate: rotate(-20deg);
}
/*oneuc*/
.theme_yakchat_going_setupOneuc{
  --text-color:  var(--onPrimary);
  --secondary-text-color: var(--onPrimary) !important;
  --background-color: var(--primary);
  --right: -4.2rem;
  --border-left: 0.9rem solid var(--background-color);
  --border-radius-left: 55rem 45rem;
  --border-radius-leftT: 1rem 25rem;
  --rotate: rotate(-20deg);
}


.justification_end {
  --direction: flex-end;
}
.justification_start {
  --direction: flex-start;
}
.sendingUser {
  font-size: 14px;
  color: var(--onsurfacehigh-text);
  margin: 0px 19px -14px 0;
}
.ReactGridGallery_tile
{
  background: none !important;
}
.bubble {
  position: relative;
  text-align: left;
  border-radius: 0.75rem;
  /* padding: 0.25rem; */
  color: var(--text-color);
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  max-width: 30em;
  min-width: 8rem;
  min-height: 2rem;
  margin: 1.0rem;
}
.theme_yakchat_going {
  display: inline-flex;
}
/* .theme_yakchat_going:after {
  content: '';
  top: -3px;
  right: -42px;
  position: absolute;
  border: -19px solid;
  display: block;
  width: 44px;
  height: 19px;
  background-color: transparent;
  border-bottom-left-radius: 49%;
  border-bottom-right-radius: 80%;
  box-shadow: -25px 7px 0px 0px var(--primary);
}
.theme_yakchat_coming:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 7px;
  left: -9px;
  height: 14px;
  width: 14px;
  background: var(--onsurfacelowest-text);
  border-bottom-left-radius: 61px;
} */

/* .bubble:after {
  content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 46px solid transparent;
	border-right-color: #00aabb;
	border-left: 0;
	border-bottom: 0;
	margin-top: -23px;
	margin-left: -46px;
} */

#cowtail-10 {
  fill: var(--primary);
  position: absolute;
  top: 0.3rem;
  right: -1.2rem;
  z-index: 0;
  transform: rotate(-10deg);
}
#cowtail--10 {
  fill: var(--onsurfacelowest-text);
  position: absolute;
  top: 1px;
  left: -1.2rem;
  z-index: 0;
  transform: rotate(10deg);
}

.bubble_text {
  line-height: 1.5;
  margin: 0.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  max-width: 30em;
  word-wrap: break-word;
  overflow-y: auto;
  overflow-x: hidden;
}


.bubble_container {
  display: flex;
  /* width: min(90%, 70.5rem); */
  width: 95%;
  justify-content: var(--direction);
  margin-inline: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.img_bubble {
  display: block;
  cursor: pointer;
  max-height: 20em;
  object-fit: fill;
  border-radius: 0.875rem;
  padding: 0.25rem;
  overflow-y: hidden;
}
.img_bubble:hover {
  opacity: 0.8;
  transition: opacity 0.15s ease-in;
  border: 3px solid var(--background-color);
}

.fullScreen_img {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
}
.portrait {
  width: 100%;
  height: auto;
  min-height: 300px;
}

.landscape {
  width: auto;
  height: 100%;
}

.fullScreen_img img {
  max-width: 100%;
  height: auto;
  display: block;
}

.hide {
  display: none;
}

.bubble_time {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  color: var(--secondary-text-color);
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
  align-items: flex-end;
}
.bubble_time span {
  font-size: 0.75rem;
  margin-inline: 0.25rem;
}
.bubble_time svg {
  fill: var(--secondary-text-color);
}
.chat_scroll {
  overflow-y: auto;
  overflow-x: unset;
  height: 100%;
  width: 100%;
  overscroll-behavior: none;
}
.chat_scroll > * {
  box-sizing: border-box;
}
.chat_data {
  color: var(--onsurfacehigh-text);
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
}
.chat_data::after,
.chat_data::before {
  content: "";
  height: 0.5px;
  width: 30%;
  margin: 1rem;
  display: block;
  background-color: var(--onsurfacelow-text);
}

[data-tooltip]:before {
  position: absolute;
  content: attr(data-tooltip);
  opacity: 0;
  border-radius: 0.5rem;
  color: var(--text-color);
  background-color: var(--background-color);
  padding: 0.5rem;
  max-width: 20em;
}

[data-tooltip]:not([data-tooltip-persistent]):before {
  pointer-events: none;
}
.bubble_time [data-tooltip]:before {
  right: 0px;
  bottom: -8px;
}
[data-tooltip]:hover:before {
  opacity: 1;
}

.skeleton {
  background-color: var(--background-color);
  border-radius: 0.875rem;
  margin: 0.25rem;
}
.skeleton.text {
  width: 100%;
  height: 0.75rem;
}
.skeleton.avatar {
  width: 100%;
  display: block;
  cursor: pointer;
  height: 20rem;
  object-fit: fill;
  border-radius: 0.875rem;
  padding: 0.25rem;
}

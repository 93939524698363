body {
  padding: 0px;
  margin: 0px;
  background: var(--background);
  padding: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: var(--onsurfacehighest);
  font-size: 16px;
}
html {
  scroll-behavior: smooth;
}
::-moz-selection { 
  color: var(--surface) !important;
  background:var( --secondary) !important;
}

::selection {
  color: var(--surface) !important;
  background:var( --secondary) !important;
}

.register_section ::-moz-selection {
  color: #fff !important;
  background:#000 !important;
}

.register_section ::selection {
  color: #fff !important;
  background:#000 !important;
}

.calltowerSection_register ::-moz-selection {
  color: #fff !important;
  background:#005568 !important;
}

.calltowerSection_register ::selection {
  color: #fff !important;
  background:#005568 !important;
}
.commsSection_register ::-moz-selection {
  color: #fff !important;
  background:#005568 !important;
}

.commsSection_register ::selection {
  color: #fff !important;
  background:#005568 !important;
}

.OneNet_register ::-moz-selection {
  color: #fff !important;
  background:#241F58  !important;
}

.OneNet_register ::selection {
  color: #fff !important;
  background:#241F58  !important;
}

.Oneuc_register ::-moz-selection {
  color: #fff !important;
  background:#492575  !important;
}

.Oneuc_register ::selection {
  color: #fff !important;
  background:#492575  !important;
}

.Mobex_register ::-moz-selection {
  color: #fff !important;
  background:#241F58  !important;
}

.Mobex_register ::selection {
  color: #fff !important;
  background:#241F58  !important;
}
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
input::-webkit-search-cancel-button {
  background: url(./images/icons_cross.svg);
  background-repeat: no-repeat;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  background-position: center;
  opacity: 1;
}

:root .light-theme {
  --primary: #fcbf00;
  --primary-hover: #f6b800;
  --primary-active: #ebb000;
  --secondary: #000000;
  --secondary-hover: #262626;
  --secondary-active: #333333;
  --onsecondary:#ffffff;
  --surface: #ffffff;
  --onPrimary: #ffffff;
  --onsurfacehighest: #000;
  --onsurfacehigh: #999999;
  --onsurfacemedium: #cccccc;
  --onsurfacelow: #e5e5e5;
  --onsurfacelowest: #f2f2f2;
  --onsurfacehighest-text: #000;
  --onsurfacehigh-text: #999999;
  --onsurfacemedium-text: #cccccc;
  --onsurfacelow-text: #e5e5e5;
  --onsurfacelowest-text: #f2f2f2;
  --text: #000;
  --secondary-text: rgba(0, 0, 0, 0.87);
  --error: #b00020;
  --primary-shadow: rgba(253, 223, 127 0.5);
  --background:#F5F5F5 ;
  
  /*------Avatars-------*/
  --avatars-red: #d32f2f;
  --avatars-red-light: #ef5350;
  --avatars-purple-light: #7e57c2;
  --avatars-purple: #ba68c8;
  --avatars-navy-blue: #5c6bc0;
  --avatars-blue: #64b5f6;
  --avatars-caribbean-green: #00bfa5;
  --avatars-light-green: #81c784;
  --avatars-orange: #fb8c00;
  --avatars-light-orange: #ffb74d;
  /* -----Chips------ */
  --default-chip: #f0f0f0;
  --active-chip: #aaaaaa;
  
}

:root .dark-theme {
  --primary: #fcbf00;
  --primary-hover: #f6b800;
  --primary-active: #ebb000;
  --secondary: #fff;
  --secondary-hover: #f2f2f2;
  --secondary-active: #e5e5e5;
  --onsecondary:#000;
  --surface: #000;
  --onPrimary: #000;
  --onsurfacehighest: #fff;
  --onsurfacehigh: #666;
  --onsurfacemedium: #4d4d4d;
  --onsurfacelow: #333;
  --onsurfacelowest: #1a1a1a;
  --onsurfacehighest-text: #fff;
  --onsurfacehigh-text: #666;
  --onsurfacemedium-text: #4d4d4d;
  --onsurfacelow-text: #333;
  --onsurfacelowest-text: #1a1a1a;
  --text: #fff;
  --secondary-text: rgb(255 255 255 / 87%);
  --error: #cf6679;
  --background: #1F1F1F;
  /*------Avatars-------*/
  --avatars-red: #d32f2f;
  --avatars-red-light: #ef5350;
  --avatars-purple-light: #7e57c2;
  --avatars-purple: #ba68c8;
  --avatars-navy-blue: #5c6bc0;
  --avatars-blue: #64b5f6;
  --avatars-caribbean-green: #00bfa5;
  --avatars-light-green: #81c784;
  --avatars-orange: #fb8c00;
  --avatars-light-orange: #ffb74d;
}

:root .call-tower-theme {
  --primary: #6bbc15;
  --primary-hover: #60A913;
  --primary-active: #569611;
  --secondary: #005568;
  --secondary-hover: #004D5E;
  --secondary-active: #004453;
  --onsecondary:#ffffff;
  --surface: #ffffff;
  --onPrimary: #ffffff;
  --onsurfacehighest: #000000;
  --onsurfacehigh: #999999;
  --onsurfacemedium: #cccccc;
  --onsurfacelow: #e5e5e5;
  --onsurfacelowest: #f2f2f2;
  --onsurfacehighest-text: #000;
  --onsurfacehigh-text: #999999;
  --onsurfacemedium-text: #cccccc;
  --onsurfacelow-text: #e5e5e5;
  --onsurfacelowest-text: #f2f2f2;
  --text: #000;
  --secondary-text: rgba(0, 0, 0, 0.87);
  --primary-shadow:rgba(107,	188,	21, 0.5);
  --error: #b00020;
  --background:#F3F2F1;
  /*------Avatars-------*/
  --avatars-red: #d32f2f;
  --avatars-red-light: #ef5350;
  --avatars-purple-light: #7e57c2;
  --avatars-purple: #ba68c8;
  --avatars-navy-blue: #5c6bc0;
  --avatars-blue: #64b5f6;
  --avatars-caribbean-green: #00bfa5;
  --avatars-light-green: #81c784;
  --avatars-orange: #fb8c00;
  --avatars-light-orange: #ffb74d;
}


:root .calltowerdark-theme {
  --primary: #60A913;
  --primary-hover: #569611;
  --primary-active: #569611;
  --secondary: #005568;
  --secondary-hover: #004D5E;
  --secondary-active: #004453;
  --surface: #000000;
  --onsecondary:#000000;
  --onPrimary: #FFFFFF;
  --onsurfacehighest: #fff;
  --onsurfacehigh: #999999;
  --onsurfacemedium: #4d4d4d;
  --onsurfacelow: #333;
  --onsurfacelowest: #1a1a1a;
  --onsurfacehighest-text: #fff;
  --onsurfacehigh-text: #666;
  --onsurfacemedium-text: #4d4d4d;
  --onsurfacelow-text: #333;
  --onsurfacelowest-text: #1a1a1a;
  --text: #fff;
  --secondary-text: rgb(255 255 255 / 87%);
  --error: #CF6679;
  --background:#1F1F1F;
  /*------Avatars-------*/
  --avatars-red: #d32f2f;
  --avatars-red-light: #ef5350;
  --avatars-purple-light: #7e57c2;
  --avatars-purple: #ba68c8;
  --avatars-navy-blue: #5c6bc0;
  --avatars-blue: #64b5f6;
  --avatars-caribbean-green: #00bfa5;
  --avatars-light-green: #81c784;
  --avatars-orange: #fb8c00;
  --avatars-light-orange: #ffb74d;
}

:root .commschoice-theme {
  --primary: #25C6DA;
  --primary-hover: #21B2C4;
  --primary-active: #1E9EAE;
  --secondary: #000045;
  --secondary-hover: #000037;
  --secondary-active: #000029;
  --surface: #FFFFFF;
  --onsecondary:#FFFFFF;
  --onPrimary: #ffffff;
  --onsurfacehighest: #010B19;
  --onsurfacehigh: #8F91A3;
  --onsurfacemedium: #BCBDC8;
  --onsurfacelow: #D2D3DA;
  --onsurfacelowest: #E9E9ED;
  --onsurfacehighest-text: #000;
  --onsurfacehigh-text: #999999;
  --onsurfacemedium-text: #cccccc;
  --onsurfacelow-text: #e5e5e5;
  --onsurfacelowest-text: #f2f2f2;
  --text: #000;
  --secondary-text: rgba(0, 0, 0, 0.87);
  --primary-shadow:rgba(107,	188,	21, 0.5);
  --error: #b00020;
  /*------Avatars-------*/
  --avatars-red: #D81A60;
  --avatars-red-light: #FF6157;
  --avatars-purple-light: #7B20A3;
  --avatars-purple: #BA68C8;
  --avatars-navy-blue: #076FFE;
  --avatars-blue: #03A9F5;
  --avatars-caribbean-green: #00BFA5;
  --avatars-light-green: #81c784;
  --avatars-orange: #fb8c00;
  --avatars-light-orange: #FDBE59;
}

:root .commschoicedark-theme{
  --primary: #25C6DA;
  --primary-hover: #21B2C4;
  --primary-active: #1E9EAE;
  --secondary: #E6F1FF;
  --secondary-hover: #CDE2FF;
  --secondary-active: #B5D4FF;
  --surface: #0E0E10;
  --onsecondary:#0E0E10;
  --onPrimary: #000045;
  --onsurfacehighest: #F4F4F6;
  --onsurfacehigh: #8F91A3;
  --onsurfacemedium: #646672;
  --onsurfacelow: #393A41;
  --onsurfacelowest: #2B2B31;
  --onsurfacehighest-text: #fff;
  --onsurfacehigh-text: #666;
  --onsurfacemedium-text: #4d4d4d;
  --onsurfacelow-text: #333;
  --onsurfacelowest-text: #1a1a1a;
  --text: #fff;
  --secondary-text: rgb(255 255 255 / 87%);
  --error: #CF6679;
  --background:#1F1F1F;
  /*------Avatars-------*/
  --avatars-red: #D81A60;
  --avatars-red-light: #FF6157;
  --avatars-purple-light: #7B20A3;
  --avatars-purple: #BA68C8;
  --avatars-navy-blue: #076FFE;
  --avatars-blue: #03A9F5;
  --avatars-caribbean-green: #00BFA5;
  --avatars-light-green: #81C784;
  --avatars-orange: #FB8C00;
  --avatars-light-orange: #FDBE59;
}

:root .onenet-theme {
  --primary: #BECE31;
  --primary-hover:  #ABB92C;
  --primary-active: #98A527;
  --secondary: #241F58;                                        
  --secondary-hover:  #3A3569;
  --secondary-active: #504C79;
  --surface: #FFFFFF;
  --onsecondary:#ffffff;
  --onPrimary: #ffffff;
  --onsurfacehighest: #010B19;
  --onsurfacehigh: #363233;
  --onsurfacemedium: #BCBDC8;
  --onsurfacelow: #D2D3DA;
  --onsurfacelowest: #E9E9ED;
  --onsurfacehighest-text: #000;
  --onsurfacehigh-text: #999999;
  --onsurfacemedium-text: #cccccc;
  --onsurfacelow-text: #e5e5e5;
  --onsurfacelowest-text: #f2f2f2;
  --text: #000;
  --secondary-text: rgba(0, 0, 0, 0.87);
  --primary-shadow:rgba(107,	188,	21, 0.5);
  --error: #b00020;
  /*------Avatars-------*/
  --avatars-red: #D81A60;
  --avatars-red-light: #FF6157;
  --avatars-purple-light: #7B20A3;
  --avatars-purple: #BA68C8;
  --avatars-navy-blue: #076FFE;
  --avatars-blue: #03A9F5;
  --avatars-caribbean-green: #00BFA5;
  --avatars-light-green: #81c784;
  --avatars-orange: #fb8c00;
  --avatars-light-orange: #FDBE59;
}

:root .onenetdark-theme{
  --primary: #BECE31;
  --primary-hover: #ABB92C;
  --primary-active: #98A527;
  --secondary: #E6F1FF;
  --secondary-hover: #CDE2FF;
  --secondary-active: #B5D4FF;
  --surface: #0E0E10;
  --onsecondary:#0E0E10;
  --onPrimary: #000000;
  --onsurfacehighest: #F4F4F6;
  --onsurfacehigh: #8F91A3;
  --onsurfacemedium: #646672;
  --onsurfacelow: #393A41;
  --onsurfacelowest: #2B2B31;
  --onsurfacehighest-text: #fff;
  --onsurfacehigh-text: #666;
  --onsurfacemedium-text: #4d4d4d;
  --onsurfacelow-text: #333;
  --onsurfacelowest-text: #1a1a1a;
  --text: #fff;
  --secondary-text: rgb(255 255 255 / 87%);
  --error:  #F43C00;  
  --background:#1F1F1F;
  /*------Avatars-------*/
  --avatars-red:  #D32F2F;
  --avatars-red-light: #EF5350;
  --avatars-purple-light: #7E57C2;
  --avatars-purple: #BA68C8;
  --avatars-navy-blue: #5C6BC0;
  --avatars-blue: #64B5F6;
  --avatars-caribbean-green: #00BFA5;
  --avatars-light-green: #81C784;
  --avatars-orange: #FB8C00;
  --avatars-light-orange: #FFB74D;
}

/*start oneuc*/
:root .oneuc-theme {
  --primary: #482474;
  --primary-hover:   #66488B;
  --primary-active: #856DA2;
  --secondary: #ED247F;  
  --onsecondary: #FFFFFF;                            
  --secondary-hover:  #F04894;
  --secondary-active: #F36DAA;
  --surface: #FFFFFF;
  --onsecondary:#FFFFFF;
  --onPrimary: #FFFFFF;
  --onsurfacehighest: #010B19;
  --onsurfacehigh: #363233;
  --onsurfacemedium: #BCBDC8;
  --onsurfacelow: #D2D3DA;
  --onsurfacelowest: #E9E9ED;
/*-------*/
  --onsurfacehighest-text: #010B19;
  --onsurfacehigh-text: #363233;
  --onsurfacemedium-text: #BCBDC8;
  --onsurfacelow-text: #D2D3DA;
  --onsurfacelowest-text: #E9E9ED;
  --text: #000;
  --secondary-text:rgba(0, 0, 0, 0.87);
  --primary-shadow:rgba(255, 255, 255, 1);
  --error:  #F43C00;
  /*------Avatars-------*/
  --avatars-red: #D32F2F;
  --avatars-red-light: #EF5350;
  --avatars-purple-light: #7E57C2;
  --avatars-purple: #BA68C8;
  --avatars-navy-blue:  #5C6BC0;
  --avatars-blue: #64B5F6;
  --avatars-caribbean-green: #00BFA5;
  --avatars-light-green: #81c784;
  --avatars-orange: #fb8c00;
  --avatars-light-orange: #FFB74D;      
}

:root .oneucdark-theme{
  --primary: #482474;
  --primary-hover:  #3C1E61;
  --primary-active: #30184D;
  --secondary: #ED247F;
  --secondary-hover: #C51E6A;
  --secondary-active: #9E1855;
  --surface: #0E0E10;
  --onsecondary:#000000;
  --onPrimary:  #FFFFFF;

  --onsurfacehighest: #F4F4F6;
  --onsurfacehigh: #8F91A3;
  --onsurfacemedium: #646672;
  --onsurfacelow: #393A41;
  --onsurfacelowest: #2B2B31;

  --onsurfacehighest-text: #F4F4F6;
  --onsurfacehigh-text: #8F91A3;
  --onsurfacemedium-text: #646672;
  --onsurfacelow-text: #393A41;
  --onsurfacelowest-text: #2B2B31;
  --text: #fff;
  --secondary-text: rgba(112, 112, 112, 1);
  --error:  #D13907;
  --background:#1F1F1F;
  /*------Avatars-------*/
  --avatars-red:  #D32F2F;
  --avatars-red-light: #EF5350;
  --avatars-purple-light: #7E57C2;
  --avatars-purple: #BA68C8;
  --avatars-navy-blue: #5C6BC0;
  --avatars-blue: #64B5F6;
  --avatars-caribbean-green: #00BFA5;
  --avatars-light-green: #81C784;
  --avatars-orange: #FB8C00;
  --avatars-light-orange: #FFB74D;
}
/*end oneuc*/

:root .mobex-theme {
  --primary: #4555AC;
  --primary-hover:   #37448A;
  --primary-active: #293367;
  --secondary: #DBE0F9;  
  --onsecondary: #4555ac;                            
  --secondary-hover:  #C5CAE0;
  --secondary-active: #AFB3C7;
  --surface: #FFFFFF;
  --onsecondary:#4555ac;
  --onPrimary: #ffffff;
  --onsurfacehighest: #000000;
  --onsurfacehigh: #363233;
  --onsurfacemedium: #CCCCCC;
  --onsurfacelow: #E5E5E5;
  --onsurfacelowest: #F2F2F2;
  --onsurfacehighest-text: #000;
  --onsurfacehigh-text: #999999;
  --onsurfacemedium-text: #cccccc;
  --onsurfacelow-text: #e5e5e5;
  --onsurfacelowest-text: #f2f2f2;
  --text: #000;
  --secondary-text: rgba(0, 0, 0, 0.87);
  --primary-shadow:rgba(107,	188,	21, 0.5);
  --error:  #F43C00;
  /*------Avatars-------*/
  --avatars-red: #D32F2F;
  --avatars-red-light: #EF5350;
  --avatars-purple-light: #7E57C2;
  --avatars-purple: #BA68C8;
  --avatars-navy-blue:  #5C6BC0;
  --avatars-blue: #64B5F6;
  --avatars-caribbean-green: #00BFA5;
  --avatars-light-green: #81c784;
  --avatars-orange: #fb8c00;
  --avatars-light-orange: #FFB74D;      
}

:root .mobexdark-theme{
  --primary: #4555AC;
  --primary-hover:  #37448A;
  --primary-active: #293367;
  --secondary: #DBE0F9;
  --secondary-hover: #C5CAE0;
  --secondary-active: #AFB3C7;
  --surface: #231F20;
  --onsecondary:#231F20;
  --onPrimary:  #FFFFFF;
  --onsurfacehighest: #F4F4F6;
  --onsurfacehigh: #999999;
  --onsurfacemedium: #4D4D4D;
  --onsurfacelow: #333333;
  --onsurfacelowest: #2B2B31;
  --onsurfacehighest-text: #fff;
  --onsurfacehigh-text: #666;
  --onsurfacemedium-text: #4d4d4d;
  --onsurfacelow-text: #333;
  --onsurfacelowest-text: #1a1a1a;
  --text: #fff;
  --secondary-text: rgb(255 255 255 / 87%);
  --error:  #D13907;
  --background:#1F1F1F;
  /*------Avatars-------*/
  --avatars-red:  #D32F2F;
  --avatars-red-light: #EF5350;
  --avatars-purple-light: #7E57C2;
  --avatars-purple: #BA68C8;
  --avatars-navy-blue: #5C6BC0;
  --avatars-blue: #64B5F6;
  --avatars-caribbean-green: #00BFA5;
  --avatars-light-green: #81C784;
  --avatars-orange: #FB8C00;
  --avatars-light-orange: #FFB74D;
}


:root .setup-theme {
  --primary: #D56F00;
  --primary-hover: #DD8C33;
  --primary-active: #E29A4D;
  --secondary: #000000;
  --secondary-hover: #333333;
  --secondary-active: #555555;
  --onsecondary:#ffffff;
  --surface: #ffffff;
  --onPrimary: #ffffff;
  --onsurfacehighest: #000000;
  --onsurfacehigh: rgb(0 0 0 / 60%);
  --onsurfacemedium: rgb(0 0 0 / 33%);
  --onsurfacelow: rgb(0 0 0 / 15%);
  --onsurfacelowest: rgb(0 0 0 / 5%);
  --onsurfacehighest-text: #000;
  --onsurfacehigh-text: #999999;
  --onsurfacemedium-text: #cccccc;
  --onsurfacelow-text: #e5e5e5;
  --onsurfacelowest-text: #f2f2f2;
  --text: #000;
  --secondary-text: rgba(0, 0, 0, 0.87);
  --primary-shadow:rgba(107,	188,	21, 0.5);
  --error: #EF5350;
  --background:#F3F2F1;
  /*------Avatars-------*/
  --avatars-red: #F27573;
  --avatars-red-light: #C94D7A;
  --avatars-purple-light: #7e57c2;
  --avatars-purple: #ba68c8;
  --avatars-navy-blue: #5c6bc0;
  --avatars-blue: #64b5f6;
  --avatars-caribbean-green: #00bfa5;
  --avatars-light-green: #FF871A;
  --avatars-orange: #fb8c00;
  --avatars-light-orange: #FF871A;
}
:root .setupdark-theme{
  --primary: #D56F00;
  --primary-hover:  #AA5900;
  --primary-active: #804300;
  --secondary: #FFFFFF;
  --secondary-hover: #E3E3E3;
  --secondary-active: #C6C6C6;
  --surface: #231F20;
  --onsecondary:#231F20;
  --onPrimary:  #FFFFFF;
  --onsurfacehighest: #FFFFFF;
  --onsurfacehigh: rgb(255 255 255 / 60%);
  --onsurfacemedium: rgb(255 255 255 / 40%);
  --onsurfacelow: rgb(255 255 255 / 20%);
  --onsurfacelowest: rgb(255 255 255 / 15%);
  --onsurfacehighest-text: #fff;
  --onsurfacehigh-text: #666;
  --onsurfacemedium-text: #4d4d4d;                                          
  --onsurfacelow-text: #333;
  --onsurfacelowest-text: #1a1a1a;
  --text: #fff;
  --secondary-text: rgb(255 255 255 / 87%);
  --error:  #BF4240;
  --background:#1F1F1F;
  /*------Avatars-------*/
  --avatars-red: #F27573;
  --avatars-red-light: #C94D7A;
  --avatars-purple-light: #7e57c2;
  --avatars-purple: #ba68c8;
  --avatars-navy-blue: #5c6bc0;
  --avatars-blue: #64b5f6;
  --avatars-caribbean-green: #00bfa5;
  --avatars-light-green: #FF871A;
  --avatars-orange: #fb8c00;
  --avatars-light-orange: #FF871A;
}

progress {

  background: var(--primary) !important;
  border-radius: 15px;
  border:0;
  color: var(--primary) !important;
  height: 10px;
  overflow: hidden;

} 
/*.progress {
  background-color: var(--primary) !important;;
  -webkit-box-shadow: none;
  box-shadow: none;
}
progress::-webkit-progress-value {
  background-color: var(--primary) !important;
  -webkit-box-shadow: none;
  box-shadow: none;
} */

progress::-webkit-progress-value {
  background-color: var(--primary) !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

progress::-moz-progress-bar {
  background-color: var(--primary) !important;
}

.nav-tabs .nav-link:hover {
  border-color: var(--onsurfacelow) !important;
}

.nav-tabs .nav-link.active:hover {
  border-bottom-color: transparent !important;
}

svg.blk_icon {
  fill: var(--text);
}

svg.wht_icon {
  fill: var(--onsecondary);
}

.btn_w_icn svg.wht_icon {
  margin-right: 5px;
}

svg.grey_icon {
  fill:var(--onsurfacehigh);
}

.btn_w_icn svg.grey_icon {
  margin-right: 5px;
}

/* body.darktheme  .ylw_bg svg.blk_icon path{
	fill:#000 !important;
} */

a,
button {
  outline: none;
}

h1,
h2,
h3,
h4 {
  margin: 0px;
}

.btn:focus {
  box-shadow: none;
}

.red {
  color: var(--error) !important;
}

.red svg path {
  fill: var(--error);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
  /* or add it to the track */
}
.ReactModalPortal{
  position: absolute;
  inset: 0px;
  border: none;
  background: transparent;
  overflow: hidden;
  border-radius: 13px !important;
  outline: none;
  padding: 0px ;
  margin: 12px !important;
}

.scroolupDiv{
 
  /* visibility: hidden; */
  /* display: flex;
  justify-content:center;
  align-items: center;
  padding-bottom: 1rem;
  padding-bottom: 1rem;  */

  height: 25px;
}

.ReactModalPortal .ReactModal__Overlay {
  margin: 11px;
}
.ReactModalPortal .ReactModal__Overlay .ReactModal__Content {
  padding: 7px !important;
  border-radius: 10px !important;
}
.ReactModalPortal .ReactModal__Overlay .ReactModal__Content > .ril-outer {
  border-radius: 10px !important;
}


.spinner {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: absolute !important;
  top: 50% !important;
  left: 55% !important;
  font-size: 45px !important;
  background: none !important;

}
.login_spinner {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: absolute !important;
  top: 63% !important;
  left: 50%;
  font-size: 45px !important;
  background: none !important;
  transform: translate(-50%, 0);
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: var(--primary) !important;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 5.3;
  animation: antSpinMove 1s infinite linear alternate;
}
/* Add a thumb */

::-webkit-scrollbar-thumb {
  background: var(--onsurfacehigh);
  width: 8px;
  border-radius: 6px;
  background-color: var(--onsurfacehigh);
}

/*----------Placeholder css start here ----------*/

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--onsurfacehigh-text);
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--onsurfacehigh-text);
}

.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--onsurfacehigh-text);
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: var(--onsurfacehigh-text);
}

/*----------Placeholder css closed here ----------*/

.form-control {
  color: var(--onsurfacehighest-text);
  letter-spacing: 0.5px;
  line-height: 24px;
  font-size: 16px;
  padding: 8px;
  height: 41px;
}
.no_border{
  border: none;
    background: none;
}

.form-control:focus {
  color: var(--onsurfacehighest-text);
  box-shadow: 0 0 0 3px var(--primary-hover);
  border-color: var(--primary);
}

select.form-control {
  background-image: url(/images/slct_arw.svg);
  background-repeat: no-repeat;
  background-position: right 12px center;
}

/*-------- Switch button ---------*/

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}
.consent_container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.consent_popup{
  height: 30%;
  width: 30%;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.call-tower-logo{
  top: "50%";
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 16px;
  border: 1px solid var(--onsurfacelow);
  background-color: var(--onsurfacelowest);
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 1px;
  bottom: 1px;
  top: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  border: 1px solid var(--primary);
  background-color: var(--primary);
}

input:focus + .slider {
  box-shadow: none;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 16px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider.round:before {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14);
  background-color: var(--surface);
}

/*--- placeholder css start here ---*/

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--onsurfacehighest-text);
}

input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--onsurfacehighest-text);
}

input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--onsurfacehighest-text);
}

input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--onsurfacehighest-text);
}

::-moz-placeholder {
  opacity: 1;
}

.cstm_btn {
  border-radius: 11px;
  color: #fff ;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 17px;
  padding: 10px 20px;
}
.cstm_btn:hover {
  color: #000;
}

.gry_btn {
  background-color: var(--onsurfacemedium);
}

.gry_btn:hover {
  background-color: #c4c3c3;
  color: var(--surface);
  outline: none;
}

.gry_btn:active {
  background-color: #aaaaaa;
  outline: none;
  color: #fff;
}

.gry_btn:focus {
  box-shadow: 0 0 0 3px rgba(204, 204, 204, 0.5);
  background-color: var(--onsurfacemedium);
  color: #fff;
}

.blk_btn {
  background-color: var(--secondary);
  color: var(--onPrimary);
}
.cancelbtn{
  background-color: #000000 !important;
  color: var(--onPrimary);
}

.blk_btn:hover {
  background-color: var(--secondary-hover);
  color: var(--onPrimary);
}

.blk_btn:active {
  background-color: var(--secondary-active);
  color: var(--onPrimary);
}

.blk_btn:focus {
  background-color: var(--secondary);
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
  color: var(--onPrimary);
}

.ylo_btn {
  background-color: var(--primary);
  color: var(--onPrimary);
}

.ylo_btn:hover {
  background-color: var(--primary-hover);
  color: var(--onPrimary);
}

.ylo_btn:active {
  background-color: var(--primary-active);
  color: var(--onPrimary);
}

.ylo_btn:focus {
  background-color: var(--primary);
  color: var(--onPrimary);
  box-shadow: 0 0 0 3px var(--primary-shadow);
}

.ylo_btn2 {
  background-color: var(--primary);
  color: var(--onPrimary);
}

.ylo_btn2:hover {
  background-color: var(--primary-hover);
  color: var(--onPrimary);
}

.ylo_btn2:active {
  background-color: var(--primary-active);
  color: var(--onPrimary);
}

.ylo_btn2:focus {
  background-color: var(--primary);
  color: var(--onPrimary);
  box-shadow: none;
}

div#wrapper {
  display: flex;
  height: calc(100vh - 20px);
  align-items: stretch;
  background: var(--surface);
  color: #fff;
  overflow: hidden;
  position: relative;
  border-radius: 14px;
  border: 1px solid #707070;
}

.left_sec {
  background: var(--surface);
  flex: 0 0 25%;
  min-width: 330px;
  max-width: 480px;
}

.right_Sec {
  flex: 1 1 auto;
  background: var(--surface);
}

.top_row {
  height: 65px;
  background: var(--surface);
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  padding: 5px 15px;
  font-family: Roboto;
  font-size: 33px;
  font-weight: 400;
  border-radius: 14px 0 0 0;
  justify-content: space-between;
  border-left: 1px solid var(--onsurfacelow);
  border-bottom: 1px solid var(--onsurfacelow);
}
.chat_bx {
  padding: 10px;
  height: calc(100vh - 150px);
  color: var(--onsurfacehighest-text);
  overflow-y: auto;
}
.chat_bx,
.top_row {
  border-bottom: 1px solid var(--onsurfacelow);
}
.msg_txtbx {
  height: 65px;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-left: 1px solid var(--onsurfacelow);
  margin-left: -1px;
}
.chat_bx_New.chat_bx {
  padding: 0;
  height: calc(100vh - 87px);
  color: var(--onsurfacehighest-text);
  display: flex;
  align-items: self-start;
  align-content: space-between;
  flex-wrap: wrap-reverse;
  flex-flow: column;
  overflow-y: auto;
  border: none;
  flex-direction: column;
}
.chat_bx_New .msg_txtbxDiv {
  align-items: flex-end;
  height: auto;
  min-height: auto;
  padding: 11px 15px;
  width: 100%;
  border-top: 1px solid var(--onsurfacelow);
}
.chat_bx_New .chat_bxInner {
  height: inherit;
  overflow-y: hidden;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
}

.chat_or_info {
  flex-direction: Row;
  display: flex;
  position: relative;
}

.chat_or_info.mobile {
  display: flex;
  transition: transform 0.5s;
}

.chat_sec {
  flex: 1 1 auto;
  transition: linear 0.1s;
  border-left: 1px solid var(--onsurfacelow);
  /* max-width: 100%; */
  margin-right: 0px !important;
}

.chat_sec.mobile {
  width: 100vw;
  /* position: absolute; */
  flex: 1;
  /* right: 100vw; */
}

.info_sec {
  background-color: var(--surface);
  /* Flex: 0 0 33%; */
  /* margin-right: -500px; */
  transition: ease-in-out 0.5s;
  border-left: 1px solid var(--onsurfacelow);
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 87px);
  width: 100%;
}

.info_sec.mobile {
  width: 100vw;
  /* position: absolute; */
  flex: 1;
}

.info_sec.show {
  margin-right: 0;
  transition: ease-in-out 0.5s;
}

a {
  color: #fff;
  text-decoration: none;
}

span.usr_img {
  display: inline-block;
  height: 60px;
  width: 60px;
  overflow: hidden;
  border-radius: 100px;
  margin-right: 8px;
  border: 3px solid rgb(239 83 80);
  box-shadow: 2px 2px 8px rgb(0 0 0 / 21%);
}

.usr_img img {
  max-width: 70px;
}

a.bck_arw {
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
}

h1 {
  color: var(--text);
  font-family: Roboto;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.23px;
  line-height: 24px;
}

h2 {
  color: var(--secondary-text);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.18px;
  line-height: 24px;
}

h3 {
  color: var(--text);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 24px;
}

h4 {
  color: var(--text);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 24px;
}

.subtitle1 {
  color: var(--text);
  font-size: 16px;
  letter-spacing: 0.14px;
  line-height: 24px;
}

.subtitle2 {
  color: var(--secondary-text);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
}

/*------ side panel css start here -------*/

.usr_img {
  border-radius: 11px;
  background-color: var(--avatars-blue);
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-transform: uppercase;
  color: #fff;
  margin-right: 15px;
}

.User_pro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
}

.User_pro.User_pro2:hover {
  box-shadow: 0 4px 5px var(--onsurfacehigh);
}

.User_pro_hover {
  box-shadow: 0 4px 5px var(--onsurfacehigh);
}

.ellipse_circle {
  width: 16px;
  height: 16px;
  background-color: var(--primary);
  border-radius: 50%;
  margin-right: 5px;
}

.usr_info {
  display: flex;
}

.usrinfo span {
  color: var(--onsurfacehigh-text);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 16px;
  display: block;
}

.stng_icon button {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid var(--onsurfacelow);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stng_icon button:after {
  display: none;
}

.stng_icon button:focus {
  border: 1px solid var(--onsurfacemedium);
  background-color: #d9d9d9;
}

.pd_15 {
  padding: 15px;
}

a.dropdown-item {
  padding: 3.5px 0;
  border-bottom: 1px solid var(--onsurfacelowest);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: var(--secondary-text);
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center !important;
  cursor: pointer;
}

a.dropdown-item:hover,
a.dropdown-item:focus {
  color: var(--secondary-text);
  background: none;
}

ul.dropdown-menu {
  padding-top: 5px;
  padding-bottom: 10px;
  width: 224px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14);
  border: 1px solid var(--onsurfacelow);
  background-color: var(--surface);
  border-radius: 10px;
}

ul.dropdown-menu:before {
  display: inline-block;
  content: "";
  border-bottom: 9px solid;
  border-right: 5px solid transparent;
  border-top: 0;
  border-left: 5px solid transparent;
  color: var(--onsurfacelow-text);
  position: absolute;
  top: -9px;
}

/* .dropdown-menu.show {
    top: 6px !important;
} */

.left_sec .nav-tabs a {
  color: var(--onsurfacehigh-text);
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 24px;
  text-align: center;
  border-radius: 10px 10px 0 0;
  margin-top: -1px;
  padding: 17px 8px;
  position: relative;
  background: var(--surface);
  border-color: var(--onsurfacelow);
}

.left_sec li.nav-item {
  width: 50%;
}

.left_sec ul#myTab {
  border-radius: 14px 0 0 0;
  border-top: 1px solid var(--onsurfacelow);
  border-bottom: transparent;
}

a#contacts_tab {
  border-radius: 10px 0 0 0;
  border-right: 0;
}

.left_sec .nav-tabs a.active {
  color: var(--onsurfacehighest-text);
  font-weight: 500;
  background: var(--surface);
  border-color: var(--onsurfacelow);
  border-bottom-color: transparent;
}

.badge {
  width: 28px;
  height: 28px;
  background-color: var(--primary);
  color: var(--onPrimary);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.32px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#msg_tab .badge {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.User_pro2 .usr_img {
  width: 40px;
  height: 40px;
  border-radius: 11px;
  background-color: var(--primary);
}

.User_pro2 h2 {
  margin-bottom: 2px;
}

.status {
  width: 16px;
  height: 16px;
  background-color: var(--primary);
  border-radius: 25px;
  display: inline-block;
  vertical-align: middle;
}

a.dwn_arw {
  display: inline-block;
  margin-left: 1px;
  transition: ease-in-out 0.5s;
}

a.dwn_arw:hover > img {
  fill: "#000";
}

.User_pro2 {
  border-radius: 11px;
  border: 1px solid var(--onsurfacelow);
  background-color: var(--surface);
  padding: 14px 15px;
}

.usr_bx2 {
  padding: 13px 15px;
}

.srch_bx {
  margin-top: 13px;
}

.srch_bx input.form-control {
  border-radius: 11px;
  background-color: var(--onsurfacelowest);
  border: none;
  padding-left: 40px;
  background: var(--onsurfacelowest) url(./images/search.svg);
  background-repeat: no-repeat;
  background-position: left 12px center;
}

.srch_bx input.form-control:focus {
  box-shadow: none;
  background-color: var(--onsurfacelowest-text);
}

.card-header {
  border: none !important;
  border-bottom: 1px solid var(--onsurfacemedium) !important;
  margin: 0 !important;
  padding: 0;
  background-color: var(--onsurfacelowest);
}

.accordion .card {
  border: none;
  border-radius: 0px;
}

.card-header button.btn {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  align-items: center;
}

span.cl_lft span.value {
  color: var(--onsurfacehigh-text);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 24px;
}

span.cl_rit {
  /* min-width: 77px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

span.cl_rit span.badge {
  margin-right: 8px;
}

.usr_img2 {
  width: 48px;
  height: 48px;
  background-color: var(--primary);
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  letter-spacing: 0.68px;
  line-height: 24px;
  text-transform: uppercase;
  overflow: hidden;
  color: var(--onPrimary);
}

.usr_img2 img {
  max-width: 55px;
}

span.tym {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 16px;
  color: var(--onsurfacehigh);
  display: inline-block;
}

.usr_txt > h3 {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
  letter-spacing: 0.14px;
}

span.usr_nme {
  text-overflow: ellipsis;
  overflow: hidden;
}

ul.usr_list > li {
  display: flex;
  align-items: center;
  position: relative;
  padding: 15px 15px;
}

ul.usr_list {
  padding: 0;
  margin: 0;
}

.usr_txt {
  padding-left: 11px;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.usr_txt .txt_div {
  color: var(--onsurfacehigh-text);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin-bottom: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

span.sub_txt {
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  color: var(--onsurfacehigh-text);
}

.usr_txt .txt_div span.badge {
  margin-top: -6px;
}

span.cl_lft {
  font-size: 18px;
  letter-spacing: 0.14px;
  line-height: 24px;
  color: var(--onsurfacehighest-text);
}

ul.usr_list li:after {
  content: "";
  display: block;
  background-color: var(--onsurfacelowest);
  height: 1px;
  position: absolute;
  left: 58px;
  bottom: 0;
  right: 15px;
}

.usr_txt .txt_div > span.icon {
  min-width: 31px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  transition: ease-in-out 0.5s;
  align-items: center;
  cursor: pointer;
}

.no_arw > a.dots::after {
  display: none;
}

a.dots {
  margin-right: -25px;
  margin-left: 9px;
  transition: ease-in-out 0.5s;
  padding: 0;
}

/* .usr_txt .txt_div > span.icon:hover a.dots {
	margin-right: -0px;
}

.usr_txt .txt_div > span.icon:hover{
	min-width: 55px;
} */

.InboxDropdown {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
}

.InboxDropdown > li {
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
}

ul.usr_list > li:last-child::after {
  display: none !important;
}

.card-header button.btn .cl_rit .arrw_dwn_img {
  transition: ease-in-out 0.5s;
}

.card-header button.btn[aria-expanded="true"] .cl_rit .arrw_dwn_img {
  transform: rotate(180deg);
}

.card-body {
  padding: 0;
}

span.icon ul.dropdown-menu {
  width: 180px;
  padding-bottom: 6px !important;
  /* transform: translate3d(0, 20px, 0px) !important; */
}

span.icon ul.dropdown-menu a.dropdown-item {
  border: none;
  height: auto;
  padding: 2px 0;
}

.usr_imgbx {
  margin: auto;
  position: relative;
  width: 53px !important;
  height: 50px;
  min-width: 53px;
  z-index: 0;
}

.grp .usr_img2 {
  width: 36px;
  height: 36px;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.grp .usr_img2 {
  bottom: 0;
  left: 0;
  z-index: 99;
  position: absolute;
  border: 1px solid var(--surface);
}

.grp .usr_img22 {
  top: 0;
  right: 0;
  z-index: 98;
  position: absolute;
  left: auto;
}

.plus_icon {
  padding: 15px 0;
  position: absolute;
  right: 15px;
  z-index: 99999;
  bottom: 0;
  /* left: 0; */
  text-align: right;
}

.plus_icon > a {
  width: 57px;
  height: 57px;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  margin: 0 0 0 auto;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 19%);
}

.plus_icon > a:hover {
  background-color: var(--primary-hover);
}

.plus_icon > a:active,
.plus_icon > a:focus {
  background-color: var(--primary-active);
}

.plus_icon > a.blk_bg:hover {
  background-color: var(--secondary-hover) !important;
}

.plus_icon > a.blk_bg:active,
.plus_icon > a.blk_bg.show {
  background-color: var(--secondary-active) !important;
}
body .plus_icon > a.blk_bg.show:hover {
  background-color: var(--secondary-hover) !important;
}

.left_sec .tab-pane {
  /* height: calc(100vh - 147px);
    overflow: auto; */
  position: relative;
}

li.slctd_dv {
  background-color: var(--onsurfacelow);
}

li.slctd_dv::after {
  display: none !important;
}

.slctd_dv span.sub_txt {
  color: var(--onsurfacehighest-text);
}

.grp_txt {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grp_txt img {
  margin-right: 5px;
}

.nw_msg {
  padding: 0 15px !important;
}

.inr_dv {
  padding: 15px 0;
  border-top: 1px solid var(--onsurfacelow);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*----- Contacts tab css -------*/

div#contacts .usr_info {
  align-items: center;
}

div#contacts span.cl_lft {
  color: var(--onsurfacehighest-text);
}

div#contacts span.cl_lft span.sub_txt2 {
  display: block;
  color: var(--onsurfacehigh-text);
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
}

div#contacts .card-header {
  height: 80px;
  align-items: center;
  display: flex;
}

div#contacts .card-header h2 {
  width: 100%;
}
div#contacts .card-header:hover {
  background-color: var(--onsurfacelow);
}

.usr_img2 {
  margin: 0 auto;
}

a.add_btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: var(--surface);
  border-radius: 20px;
  border: 1px solid var(--onsurfacelow);
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

div#contacts a.plus_icon {
  background: var(--secondary);
}

.ylw_bg {
  background: var(--primary) !important;
  border-color: var(--primary) !important;
}

.ylw_bg:hover {
  background: var(--primary-hover) !important;
  box-shadow: 0 0 0 3px var(--primary-shadow);
}

.ylw_bg:active {
  background: var(--primary-active) !important;
}

/* .ylw_bg:focus {
  background: var(--primary);

} */

.ylw_bg > svg {
  fill: var(--onPrimary) !important;
}

.grp_bx {
  width: 48px;
  height: 48px;
  min-width: 48px;
  max-width: 48px;
  border-radius: 11px;
  background-color: var(--secondary);
  color: var(--onPrimary);
  font-size: 22px;
  letter-spacing: 0.68px;
  line-height: 24px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.grry_txt {
  display: block;
  color: var(--onsurfacehigh-text);
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
}

span.left_Bx span.grp_bx {
  margin-right: 15px;
}

span.left_Bx {
  display: flex;
  align-items: center;
}

.usr_txt .txt_div > span.icon > ul {
  display: none;
}

div#contacts
  .card-header
  button.btn[aria-expanded="true"]
  .cl_rit
  .arrw_dwn_img {
  transform: rotate(0deg);
}

div#contacts
  .card-header
  button.btn[aria-expanded="false"]
  .cl_rit
  .arrw_dwn_img {
  transform: rotate(-90deg);
}

a.setting_cion {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  opacity: 0.4;
}

.cntcts_optns {
  position: absolute;
  left: -1px;
  right: -1px;
  top: 63px;
  box-shadow: 0 4px 5px var(--onsurfacehigh);
  border-radius: 0 0 11px 11px;
  border: 1px solid var(--onsurfacelow);
  background-color: var(--surface);
  /* padding: 15px 0 0; */
  z-index: 55;
  border-top: 0;
  display: none;
}

div#contacts .User_pro2 {
  position: relative;
}

.optns {
  padding: 13px 15px;
  cursor: pointer;
}

.optns .usrinfo {
  color: var(--onsurfacehighest-text);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 24px;
}

.blk_bg {
  background: var(--secondary) !important;
}

/* div#contacts .User_pro2:hover .cntcts_optns, div#msg_sec .User_pro2:hover .cntcts_optns{
	display:block;
} */

div#contacts .User_pro2:hover .dwn_arw,
div#msg_sec .User_pro2:hover .dwn_arw {
  /* opacity:0; */
}

#msg_sec .User_pro.User_pro2 {
  position: relative;
}

div#msg_sec .usr_info {
  align-items: center;
}

.pd_0 {
  padding: 0px;
}

.cntcts_optns ul.usr_list > li .usr_imgbx .usr_img2 {
  width: 40px;
  height: 40px;
  border-radius: 11px !important;
}

.cntcts_optns ul.usr_list > li {
  padding: 15px 15px;
  background-color: var(--surface);
}

.cntcts_optns ul.usr_list > li .usr_imgbx {
  height: 40px;
  width: 40px !important;
  min-width: 40px;
}

.cntcts_optns ul.usr_list > li:after {
  display: none;
}

#msg_sec .User_pro.User_pro2 .rit_bx {
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#msg_sec .User_pro.User_pro2 .rit_bx span.badge, .rit_bx span.badge  {
  transform: translateX(44px);
  transition: ease-in-out 0.5s;
}

#msg_sec .User_pro.User_pro2:hover .rit_bx span.badge {
  transform: translateX(19px);
}

/* div#msg_sec .User_pro2:hover .cntcts_optns{
	display:block;
} */

/* div#msg_sec .User_pro2:hover .dwn_arw{
	opacity:0;
} */

div#msg_sec .cntcts_optns .optns:last-child {
  border-top: 1px solid var(--onsurfacehigh);
  padding-bottom: 10px;
}

ul#contactsPlusOptions {
  list-style: none;
}

.profl_img {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 100px;
}

.cntct_profile {
  padding: 24px 10px 36px;
}

.phone_txt,
.vlu_txt {
  color: var(--onsurfacehighest-text);
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-top: 20px;
}

.cntct_info {
  border: 1px solid var(--onsurfacelow);
  background-color: var(--surface);
  border-radius: 14px 14px 0 0;
  border-bottom: none;
  margin-left: -1px;
  margin-right: -1px;
  padding: 6px 0px;
}

.grey_hedln {
  color: var(--onsurfacehigh-text);
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.14px;
  line-height: 24px;
  font-weight: 400;
}

.grey_hedln {
  color: var(--onsurfacehigh-text);
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.14px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 0;
}

label.grry_txt {
  color: var(--onsurfacehigh-text);
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 16px;
  text-transform: uppercase;
  margin-top: 15px;
}

.cntct_info .phone_txt {
  margin: 0;
}

.cntct_info h5.grey_hedln {
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.label1 .phone_txt {
  margin: 0;
  border-bottom: 1px solid var(--onsurfacelow);
  padding-bottom: 13px;
  color: var(--text);
}

input.form-control:focus {
  background-color: inherit;
}

select.form-control {
  background: var(--surface);
  background-color: var(--surface);
}

select.form-control:focus {
  background-color: inherit;
  background: inherit;
}

select.form-control option {
  background-color: var(--surface);
  background: var(--surface);
}

.cntct_info .gry_btn {
  display: block;
  width: 100%;
  margin-top: 12px;
  position: relative;
  margin-bottom: 15px;
}

.cntct_info .gry_btn img {
  position: absolute;
  left: 12px;
  top: 0;
  bottom: 0;
  margin: auto;
}

ul.optn_list {
  padding: 0;
  margin: 0;
  margin-top: 14px;
  list-style: none !important;
}

ul.optn_list a {
  color: var(--onsurfacehighest-text);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid var(--onsurfacelowest);
}

.send_form h5.grey_hedln {
  padding: 8px 15px;
}

.send_form .usr_imgbx {
  height: 40px;
  width: 40px;
  min-width: 40px;
}

.send_form .usr_imgbx .usr_img2 {
  width: 40px;
  height: 40px;
  border-radius: 11px;
  background-color: var(--primary);
}

.send_form ul.usr_list > li:after {
  display: none !important;
}

.send_form li.slctd_dv h3 {
  font-weight: 500;
  color: var(--secondary-text);
}

.send_form li.slctd_dv .txt_div span.sub_txt {
  color: var(--onsurfacehigh-text);
}

.send_form li.slctd_dv {
  padding: 11px 15px !important;
}

.send_form .txt_div {
  font-size: 12px;
}

.send_form ul.usr_list {
  overflow-y: auto;
  height: calc(100vh - 127px);
}

.profl_img.grp_pro {
  border-radius: 0;
  width: 85px;
}

.grp_pro .usr_imgbx.grp {
  height: 80px;
  width: 85px !important;
  min-width: 80px !important;
}

.grp_pro .usr_imgbx.grp .usr_img2 {
  width: 54px;
  height: 54px;
}

.cntct_profile .usr_txt {
  max-width: 250px;
  margin: 20px auto 0;
  padding: 0;
}

.cntct_profile .usr_txt h3 {
  display: block;
  white-space: pre-wrap;
  font-size: 20px;
  text-overflow: inherit;
}

.grp_pro img {
  max-width: 60px;
}

.grp_pro .usr_img2 {
  font-size: 24px;
}

.grp_profile {
  padding-bottom: 25px;
}

.brdr_tp {
  border-top: 1px solid var(--onsurfacelow);
}

.recipnts {
  border-radius: 15px 15px 0 0;
  border: 1px solid var(--onsurfacelow);
  border-bottom: none;
  margin-left: -1px;
  margin-right: -1px;
}

.recipnts h5.grey_hedln {
  padding: 8px 15px;
}

/*------- top bar css start here ------*/

.top_left h3 {
  font-size: 20px;
  overflow: hidden;
  max-width: calc(100vw - 600px);
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: block;
}

.top_left li {
  padding: 0 !important;
}

.usr_imgcon {
  display: flex;
  align-items: center;
  /* width: 60vw; */
}

/*------- bottom message bar ------*/

.msg_txtbx .icons_msg_txtbx > a,
.msg_txtbx label.browse_file a {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 5px;
  border: 1px solid var(--onsurfacelow);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  background: var(--surface);
}
.msg_txtbx label.browse_file a {
  background: transparent;
}
.msg_txtbx label.browse_file {
  border: 1px solid var(--onsurfacelow);
  background: var(--surface);
  cursor: pointer;
}
.msg_txtbx .icons_msg_txtbx > a:hover,
.msg_txtbx label.browse_file:hover,
.stng_icon .dropdown-toggle:hover {
  background-color: var(--onsurfacelow);
  border-color: var(--onsurfacelow);
}
.msg_txtbx .icons_msg_txtbx > a:focus,
.stng_icon :focus .msg_txtbx label.browse_file:focus {
  background-color: #d9d9d9;
  border-color: var(--onsurfacelow);
}
.stng_icon .dropdown-toggle.show:focus {
  background-color: #d9d9d9;
}
.msg_txtbx .icons_msg_txtbx > a:focus,
.msg_txtbx label.browse_file:focus {
  background: var(--surface);
  border-color: var(--onsurfacelow);
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
}

.msg_txtbx a.ylw_bg,
.msg_txtbx a.gry_bg {
  margin-right: 0;
  margin-left: 15px;
}

.ant-collapse.ant-collapseheader > .ant-collapse-item > .ant-collapse-header {
  border: none !important;
  border-bottom: 1px solid var(--onsurfacemedium) !important;
  margin: 0 !important;
  padding: 12px 16px !important;
  background-color: var(--onsurfacelowest);
  border-radius: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ant-collapse.ant-collapseheader > .ant-collapse-item > .ant-collapse-header::before, .ant-collapse.ant-collapseheader > .ant-collapse-item > .ant-collapse-header::after{
  display: none;
}
  .ant-collapse.ant-collapseheader > .ant-collapse-item > .ant-collapse-header:hover{
    background-color: var(--onsurfacelow);
      } 
  .ant-collapse.ant-collapseheader > .ant-collapse-item {
    border: none;
    border-radius: 0 !important;
}
.ant-collapseheader .ant-collapse-content {
  border: none;
}
.ant-collapseheader .ant-collapse-content .ant-collapse-content-box {
  padding: 0;
} 
.ant-collapse.ant-collapseheader {
  border: none;
}
.ant-collapse.ant-collapseheader > .ant-collapse-item > .ant-collapse-header .arrw_dwn_img {
  transition: .5s ease-in-out;
}
.ant-collapse.ant-collapseheader > .ant-collapse-item > .ant-collapse-header[aria-expanded=false] .arrw_dwn_img {
  transform: rotate(-90deg);
  transition: .5s ease-in-out;
}
.ant-collapse.ant-collapseheader > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: none;
}
.cardInnerHeader.ant-collapseheader span.cl_lft {
  max-width: 80%;
}





.srch_input {
  width: 100%;
  margin: 0;
}

.srch_input input.form-control {
  padding-left: 15px;
  background-image: none;
  width: 100%;
}

label.browse_file {
  height: 40px;
  width: 40px;
  margin-right: 5px;
  min-width: 40px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border-radius: 100px;
}

label.browse_file input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  width: 100%;
}

.cntct_frm {
  max-width: 663px;
  padding: 60px 10px 30px 70px;
}

label.form-label {
  color: var(--onsurfacehigh-text);
  font-size: 10px;
  letter-spacing: 0.33px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.form-control {
  border-radius: 11px;
  border: 1px solid var(--onsurfacelow);
  background-color: var(--surface);
}

.cntct_frm .form-control {
  padding-left: 15px;
}

.msg_txtbx > button.btn:first-child {
  margin-right: 14px;
}

.msg_txtbx > button.btn:last-child {
  margin-right: 0;
}

.btn_wid_drp button.btn:first-child {
  border-radius: 11px 0 0 11px;
  padding: 10px 2px 10px 10px;
}

.btn_wid_drp button.btn.dropdown-toggle {
  border-radius: 0 11px 11px 0;
  padding: 10px 7.5px;
}

.btn_wid_drp button.btn.dropdown-toggle:after {
  display: none;
}

.btn_wid_drp ul.dropdown-menu {
  width: 100%;
  background: var(--secondary);
  border: none;
  max-width: 100%;
  top: 0px !important;
  border-radius: 11px 11px 0 0;
  padding: 0;
  box-shadow: none;
  min-width: 18px;
  left: 0 !important;
  right: 0;
  transform: translate3d(0px, -42px, 0px) !important;
}

.btn_wid_drp ul.dropdown-menu a {
  display: block;
  text-align: center;
  padding: 10px 5px;
  color: var(--surface);
  vertical-align: middle;
  line-height: normal;
  font-size: 16px;
  transition: ease-in-out 0.5s;
  border: none;
}

.btn_wid_drp {
  display: flex;
  position: relative;
}

.btn_wid_drp button.btn.dropdown-toggle img {
  transition: ease-in-out 0.5s;
}

.btn_wid_drp.active button.btn:first-child {
  border-radius: 0 0 0 11px;
}

.btn_wid_drp.active button.btn.dropdown-toggle {
  border-radius: 0 0 11px 0;
}

.btn_wid_drp button.btn.dropdown-toggle[aria-expanded="true"] img {
  transform: rotate(180deg);
}

.crcl_icon {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--surface);
  border: 1px solid var(--onsurfacelow);
  margin-left: 15px !important;
}

.gry_bg {
  background: var(--onsurfacelow);
}

.gry_bg path {
  /* fill: var(--onsurfacehighest); */
}

.cntct_frm .phone_txt {
  font-size: 18px;
}

.cntct_frm label.grry_txt {
  margin-top: 5px;
}

.cntc_open label.grry_txt {
  margin-top: 22px;
}

ul.optn_list a svg {
  vertical-align: middle;
  line-height: normal;
  margin-top: -5px;
}

ul.optn_list > li:hover {
  background: var(--onsurfacelowest);
}

.cntct_open ul.usr_list li {
  padding: 15px 10px;
  cursor: pointer;
  margin: 0 -15px;
}

.cntct_open ul.usr_list li:after {
  display: block !important;
}

.pdb_15 {
  padding-bottom: 15px !important;
}

ul.cntct_grps {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.cntct_grps span.grp_bx_txt {
  color: var(--onsurfacehighest-text);
  font-size: 18px;
  letter-spacing: 0.14px;
  line-height: 24px;
}

.cntct_grps li {
  padding: 15px 0;
  position: relative;
}

.cntct_grps li:after {
  content: "";
  display: block !important;
  background: var(--onsurfacelowest);
  height: 2px;
  position: absolute;
  left: 58px;
  bottom: 0;
  right: 15px;
}

/*--- 23 sept css start here ---*/

.modal-title {
  color: var(--secondary-text);
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.18px;
  line-height: 24px;
}

.cmn_modl {
  max-width: 665px;
}

.stng_dtls {
  padding: 15px 25px;
}

.stng_dtls .vlu_txt {
  font-size: 18px;
}

.switch_btns {
  margin: 33px 0;
}

.stng_dtls .form-control {
  padding-left: 15px;
}

.no_dots {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.btn_list li {
  display: flex;
  justify-content: space-between;
  min-height: 57px;
  align-items: center;
  border-bottom: 1px solid var(--onsurfacelowest);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 24px;
  color: var(--onsurfacehighest-text);
}

ul.btn_list {
  margin-top: 13px;
}

.modal-header {
  border-bottom: 1px solid var(--onsurfacelow);
}

.yr_dtls h2 {
  color: var(--text);
  margin-bottom: 11px;
}

.modal-content {
  background-color: var(--surface) !important;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14) !important;
  border-radius: 14px;
  border: 1px solid var(--onsurfacelow) !important;
}
/* setting_select_option{
  background-color: var(--surface);
}
setting_select_option :active:hover :focus{
  background-color: var(--onsurfacehigh);
} */

.cntct_tp_sec h4 {
  font-size: 16px;
}

.cntct_tp_sec p {
  color: var(--onsurfacehigh-text);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
}

.cntct_tp_sec h4 {
  font-size: 16px;
  margin: 8px 0 10px;
}

.sm_mdl {
  max-width: 312px;
}

.no_hlfbrder li:after {
  display: none !important;
}

.no_hlfbrder li {
  border-bottom: 1px solid var(--onsurfacelow);
}

.mt_10 {
  margin-top: 10px;
}

.radio_btn {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  border: 1px solid var(--onsurfacelow) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio_btn img {
  opacity: 0;
}

.cntct_grps label input[type="radio"] {
  display: none;
}

.cntct_grps li label > input[type="radio"]:checked ~ .radio_btn {
  background-color: var(--primary);
  border-color: var(--primary);
}

.cntct_grps li label > input[type="radio"]:checked ~ .radio_btn img {
  opacity: 1;
}

.cntct_grps .radio_btn {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.modal-backdrop.show {
  opacity: 0.2;
}

.cntct_view li {
  padding: 15px 0 !important;
}

.cntct_hedr span.sub_txt {
  color: var(--onsurfacehigh-text);
  font-size: 16px;
  letter-spacing: 0.14px;
  line-height: 24px;
}

.cntct_view {
  width: 100%;
  max-width: 100% !important;
}

.cntct_view .vlu_txt {
  margin: 0;
}

.cntct_hedr {
  margin-bottom: 25px;
}

.top_left span.grp_bx_txt {
  color: var(--onsurfacehighest-text);
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 24px;
}

.btn_w_icn img:first-child {
  margin-right: 8px;
}

.btn_w_icn {
  display: flex;
  align-items: center;
}

.plus_icon ul.optn_lst {
  margin: 0;
  padding: 0;
}

.plus_icon ul.optn_lst a {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  background-color: var(--secondary);
  color: var(--onsecondary);
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  padding: 7px 20px;
  display: inline-block;
  margin-bottom: 14px;
  vertical-align: middle;
}

.plus_icon ul.optn_lst a img {
  margin-right: 11px;
  display: inline-block;
}

.opened a.circle {
  transform: rotate(45deg);
}

.grp_icn {
  border-radius: 0;
}

.grp_icn span.grp_bx {
  width: 80px !important;
  height: 80px !important;
  min-width: 80px;
  font-size: 35px;
  margin: 0px !important;
}

.card {
  background: var(--surface);
}

.btn_wid_drp ul.dropdown-menu::before {
  display: none;
}

div#contacts div.contacts_listN,
div#msg_sec div.accordion {
  overflow: auto;
}

div#contacts {
  overflow: visible;
}

div#messagesGroupInboxDropdownDiv:hover a.dwn_arw svg path,
div#contactsGroupInboxDropdownDiv:hover a.dwn_arw svg path {
  fill: var(--secondary);
}

.cntcts_optns span.ellipse_circle {
  min-width: 16px !important;
}

.cntcts_optns ul.usr_list > li:hover {
  background-color: var(--onsurfacelowest);
}

.cntcts_optns .optns:hover {
  background: var(--onsurfacelowest);
}

.cntcts_optns ul.usr_list > li.slct {
  background-color: #fff;
}

h2.slct {
  letter-spacing: 0.18px;
  font-size: 20px !important;
  font-weight: 500;
}

/* New Group Page */

.group_onbox {
  border-radius: 11px;
  border: 1px solid var(--onsurfacelow);
  background-color: var(--onPrimary);
  font-size: 16px;
  font-weight: 400;
  padding: 7px 15px;
  color: #000;
}

/*---18 DEc css start here --*/

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paper2 {
  margin-right: 0px;
  position: static;
  /* transform: translate(0px, 0px) !important; */
}

.MuiPaper-root {
  background: var(--surface) !important;
}

.MuiDrawer-docked {
  flex: 0 0 33% !important;
  min-width: 200px;
  max-width: 480px;
  position: relative;
  transition: ease-in-out 0.5s;
  -webkit-transition: ease-in-out 0.5s;
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paper2 {
  transition: ease-in-out 0.8s !important;
  -webkit-transition: ease-in-out 0.8s !important;
}

.MuiDrawer-root.MuiDrawer-docked.Drawer.Drawer-Open {
  flex: 0 0 0% !important;
  min-width: 0px;
}

.chat_sec {
  position: relative;

}

.contactOpen-sidePanel-GroupNames {
  cursor: pointer;
  margin: 0 -10px;
  padding-left: 10px !important;
}

.contactOpen-sidePanel-GroupNames:hover {
  background: var(--onsurfacelowest);
}

li.active {
  background: var(--onsurfacelow);
}

.cntct_info li,
.cntct_info .label1 {
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.cntct_info button {
  margin: 0 15px !important;
  width: -webkit-fill-available !important;
}

ul.optn_list a > span:first-child {
  width: 35px;
}

.autocomplete-dropdown .antd_auto_dropdown {
  max-height: 170px;
  overflow: scroll !important;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14);
  /* margin-top: 100px;
    padding-top: 10px; */
  /* position: relative;
    bottom: 50px!important; */
  /* transform: translate(0, 30px)!important; */
  border: 1px solid #f2f2f2;
  border-radius: 11px;
  z-index: 9999;
  width: "100%" !important;
}
/* dropdown */

/* .autocomplete-dropdown.autocomplete-dropdown-ContactOpen {
  left: calC(330px + 10px + 30px + 5px + 8px)!important;
  width: calC(100vw - 330px - 10px - 30px - 55px)!important;
}

.autocomplete-dropdown.autocomplete-dropdown-group {
  left: calC((100vw - 330px) / 2)!important;
  width: calC(100vw - 485px - 168px - 10px - 30px - 55px)!important;
} */
/* Chip css start here */
.ant-select-multiple .ant-select-selection-item-remove {
  align-items: center;
  display: flex !important;
  color: var(--onsurfacehigh-text) !important;
}
.ant-select-multiple .ant-select-selection-item {
  height: 33px !important;
  align-items: center;
  margin: 2px 4px !important;
  border-radius: 7px !important;
  background: var(--default-chip) !important;
  color: var(--text) !important;
}
.ant-select-multiple .ant-select-selection-item:hover,
div.ReactTags__selected span.ReactTags__tag:hover {
  background: var(--onsurfacelow) !important;
}
.ant-select-multiple .ant-select-selection-item:active,
div.ReactTags__selected span.ReactTags__tag:active {
  background: var(--active-chip) !important;
}
div.ReactTags__selected span.ReactTags__tag {
  background: var(--default-chip) !important;
  border-radius: 7px !important;
}
div.ReactTags__selected span.ReactTags__tag button.ReactTags__remove {
  color: var(--onsurfacehigh-text) !important;
}
/*.ant-select-open .ant-select-selection-item{

}*/
/* Chip css end here */

.ant-select,
.ant-select-selector {
  /* border: none !important;
  outline: none !important;
  box-shadow: none !important; */
  background-color: var(--surface) !important;
}
.sharedwith-dropdown:active,
.sharedwith-dropdown:focus,
.sharedwith-dropdown.ant-select-open {
  color: var(--onsurfacehighest-text) !important;
  box-shadow: 0 0 0 3px var(--primary-hover) !important;
  border-color: var(--primary) !important;
}
.sharedwith-dropdown {
  letter-spacing: 0.5px;
  line-height: 24px;
  font-size: 16px;
  padding: 8px;
  min-height: 41px !important;
  border: 1px solid var(--onsurfacelow) !important;
  border-radius: 11px !important;
}
.ant-select-item-option-state {
  display: none !important;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  cursor: text;
  min-height: 38px;
}
.ant-select:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.autocomplete-dropdown .antd_auto_dropdown {
  max-height: 170px;
  overflow: scroll !important;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14);
  /* margin-top: 100px;
    padding-top: 10px; */
  /* position: relative;
    bottom: 50px!important; */
  /* transform: translate(0, 30px)!important; */
  border: 1px solid #f2f2f2;
  border-radius: 11px;
  z-index: 9999;
  width: "100%" !important;
}

.ant-select-multiple .ant-select-selection-item-remove {
  align-items: center;
  display: flex !important;
  color: var(--onsurfacehigh-text) !important;
}
.ant-select-multiple .ant-select-selection-item {
  height: 33px !important;
  align-items: center;
  margin: 2px 4px !important;
  border-radius: 7px !important;
  background: var(--default-chip) !important;
  color: var(--text) !important;
}
.ant-select-multiple .ant-select-selection-item:hover,
div.ReactTags__selected span.ReactTags__tag:hover {
  background: var(--onsurfacelow) !important;
}
.ant-select-multiple .ant-select-selection-item:active,
div.ReactTags__selected span.ReactTags__tag:active {
  background: var(--active-chip) !important;
}
div.ReactTags__selected span.ReactTags__tag {
  background: var(--default-chip) !important;
  border-radius: 7px !important;
}
div.ReactTags__selected span.ReactTags__tag button.ReactTags__remove {
  color: var(--onsurfacehigh-text) !important;
}
.sharedwith-dropdown:active,
.sharedwith-dropdown:focus,
.sharedwith-dropdown.ant-select-open {
  color: var(--onsurfacehighest-text) !important;
  box-shadow: 0 0 0 3px var(--primary-hover) !important;
  border-color: var(--primary) !important;
}
.ant-select-item-option-state {
  display: none !important;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  cursor: text;
  min-height: 38px;
}
.ant-select:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.ant-select:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.rc-virtual-list-scrollbar {
  display: none !important;
}
.ant-select-dropdown {
  border-radius: 11px !important;
}
.ant-select-dropdown.autocomplete-dropdown.ant-select-dropdown-placement-bottomLeft
  .ant-select-item.ant-select-item-option {
  color: var(--text);
}

.ant-select-dropdown.autocomplete-dropdown.ant-select-dropdown-placement-bottomLeft {
  background: var(--surface);
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14);
  border-radius: 11px;
  border: 1px solid var(--onsurfacelowest);
}
.sharedwith-dropdown .ant-select-selector {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 11px !important;
  font-size: 16px !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--onsurfacelowest) !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #fff !important;
}
.autocomplete-dropdown::-webkit-scrollbar {
  width: 0;
}

.autocomplete-dropdown::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 0;
}

.autocomplete-dropdown::-webkit-scrollbar-thumb {
  border-radius: 0;
  -webkit-box-shadow: none;
}
/* new try */
.ant-select-selection-placeholder {
  /* padding-left: 20px; */
  display: flex !important;
  color: var(--onsurfacehigh) !important;
  font-size: 16px !important;
}

.form-control.no_editing {
  border-color: transparent !important;
  border-bottom: 1px solid var(--onsurfacelow) !important;
  border-radius: 0px;
  box-shadow: none;
  outline: none;
  background: none;
}

.icons_msg_txtbx {
  display: flex;
  align-items: center;
}
.msg_txtbx > a,
label.browse_file a {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 5px;
  border: 1px solid var(--onsurfacelow);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  background: var(--surface);
}

.rc-virtual-list-holder-inner > div:not(:last-child) {
  position: relative;
}
.rc-virtual-list-holder-inner > div:not(:last-child):before {
  content: "";
  background: #f0f0f0;
  width: 95%;
  position: absolute;
  height: 1px;
  bottom: 0;
}
.rc-virtual-list-holder-inner > div {
  font-size: 18px;
  line-height: 31px;
}

div#root {
  overflow: hidden;
}
.InboxDropdown {
  margin: 0;
  padding: 3px 9px !important;
}

/* shared-with-new-csss */
/* .sharedwith-dropdown.ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: auto;
  margin: 2px 0;
  line-height: 24px;
  content: "Start typing inbox name...";
  white-space: nowrap;
  font-size: 16px;
  color: var(--onsurfacehigh-text);
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0;
}
.sharedwith-dropdown .ant-select-selector {
  padding-left: 11px !important;
}
.sharedwith-dropdown span.ant-select-selection-placeholder {
  display: none !important;
} */
.sharedwith-dropdown .ant-select-selection-item-remove span.anticon-close {
  vertical-align: middle;
  margin-top: -2px;
}
.sharedwith-dropdown.ant-select-multiple .ant-select-selection-placeholder {
  left: 14px;
}

.chatSecActive .msg_txtbx {
  height: 120px;
  min-height: 65px;
}
.chatSecActive .chat_bx {
  height: calc(100vh - 208px);
}
.contact_accordions {
  border: none !important;
}
.contact_accordions .cntct_grps li:first-child,
.contact_accordions .optn_list li:first-child a {
  padding-top: 6px;
}
.contact_accordions .optn_list {
  margin-top: 5px;
}
.contact_accordions .ant-collapse,
.contact_accordions .ant-collapse-content {
  border: none;
}
.contact_accordions .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0;
}
.contact_accordions .ant-collapse-item {
  border: 1px solid var(--onsurfacelow);
  background-color: var(--surface);
  border-radius: 14px 14px 0 0 !important;
  border-bottom: none !important;
}
.contact_empty {
  height: 69px;
}
.ant-collapse-header svg {
  transition: all 0.5s;
}
.ant-collapse-header[aria-expanded="false"] svg {
  transform: rotate(-90deg);
}
.contact_accordions .ant-collapse,
.contact_accordions .ant-collapse-content {
  border: none;
}

/* new-css-18-Jan */
.error-div {
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.error-icon {
  font-size: 14px;
  color: var(--error);
}
.error-span {
  font-size: 12px;
  color: var(--error);
  display: flex;
  align-items: center;
  padding: 0px 4px;
}

.form-control-error {
  border-radius: 11px;
  border: 1px solid var(--error);
  background-color: var(--surface);
}
input.form-control-error:focus {
  box-shadow: 0 1px 4px 3px rgb(176 0 32 / 17%);
  border-color: #b00020 !important;
}
.group_action-li a {
  height: 60px;
  display: flex;
  align-items: center;
}
ul.usr_list li.number_list .txt_div {
  font-size: 17px;
  color: var(--text);
}
ul.usr_list li.number_list .txt_div .sub_txt {
  color: var(--text);
}
ul.usr_list li.number_list:last-child::after,
ul.usr_list li.number_list::after {
  display: block !important;
}
ul.usr_list li.number_list .usr_img2 {
  background-color: transparent;
}
.emoji-focus {
  box-shadow: 0 0 0 3px rgb(0 0 0 / 15%);
  border-color: #d9d9d9 !important;
  background: #d9d9d9 !important;
}
.msg_txtbx .icons_msg_txtbx > a.emoji-focus:hover {
  box-shadow: 0 0 0 3px rgb(0 0 0 / 15%);
  border-color: var(--onsurfacemedium) !important;
  background: #fff !important;
}
.hide-error {
  display: none;
}
/* new css---- */
.ant-select:focus-within {
  box-shadow: 0 0 0 3px var(--primary-hover) !important;
  border-color: var(--primary) !important;
}

.cardInnerHeader {
  position: relative;
}
.cardInnerHeader span.cl_rit {
  min-width: 51px;
}
.cardInnerHeader a.setting_cion {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translate(0px, -50%);
}
.timeOutBox {
  background: var(--onPrimary);
  max-width: 469px;
  margin: 30px auto 10px;
  border-radius: 25px;
  padding: 40px 30px 50px;
  text-align: center;
  color:var(--onPrimary);
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.25);
}
.inner_box {
  max-width: 550px;
  margin: 0 auto;
  padding: 0 22px;
}
.inner_box  .icons {
  margin-bottom: 20px;
}
.inner_box h1 {
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 8px;
  color: #222;
}
.inner_box .subheading {
  font-size: 16px;
  margin: 0;
  color: #777878;
  font-style: italic;
}
.btnDiv {
  margin-top: 55px;
}
.signInBtn {
  background: var(--primary);
  font-weight: 600;
  color: #fff !important;
  transition: ease-in-out .5s;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  height: 42px !important;
  display: inline-block;
  max-width: 120px;
  width: 100%;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: .5px;
  padding: 9px 20px;
  border-radius: 11px;
}
.signInBtn :hover {
  background:var(--primary-hover);
color: #fff;
}

.cardInnerHeader span.cl_lft{
  max-width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardInnerHeader span.cl_lft span.grp_bx_txt {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}
.messageFAB{
  left: 30% !important;
}
/* .ant-affix {
  position: fixed;
  z-index: 10;
  left: 10% !important;
} */
.newFABMessageButton{
  display: none !important;
}

.ctThem >g>g>g:first-child>g:first-child path {
  fill: var(--primary);
}

img{
  max-width: 100%;
  width: auto;
}
span.cl_rit img {
  width: auto !important;
}
.topBar_mobile, .topBar_mobileDiv{
  width: 100%;
  display: flex;
  align-items: center;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}
/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.oneuc_txt
{
  left: auto !important;  
}
.calltowerLogin .calltower_txt, .commsLogin .calltower_txt, .oneNetLogin .calltower_txt, .commsLogin .calltower_txt, .mobexLogin .calltower_txt,.oneucLogin .calltower_txt, .setupLogin .calltower_txt  {
  position: absolute;
  right: 25px;
  left: auto;
  justify-content: flex-end;
  bottom: 25px;
  padding: 0;
  max-width: 200px;
}
.calltowerLogin .authen_txt, .commsLogin .authen_txt {
  bottom: 25px;
}
.calltowerLogin .calltower_txt img, .commsLogin .calltower_txt img ,.oneucLogin .calltower_txt img  {
  margin-right: 0;
}
.calltowerLogin .authen_txt svg path, .commsLogin .authen_txt svg path {
  fill: var(--primary);
}
.calltowerLogin .authen_txt svg, .commsLogin .authen_txt svg, .mobexLogin .oneucLogin  .authen_txt svg{
  margin-right: 10px;
}

.oneNetLogin .authen_txt, .commsLogin .authen_txt , .mobexLogin .oneucLogin .authen_txt{
  bottom: 25px;
}
.oneNetLogin .calltower_txt img, .commsLogin .calltower_txt img, .mobexLogin img, .oneucLogin .calltower_txt img{
  margin-right: 0;
}
.oneNetLogin .authen_txt svg path, .commsLogin .authen_txt svg path, .mobexLogin .oneucLogin .authen_txt svg path {
  fill: var(--primary);
}
.oneNetLogin .authen_txt svg, .commsLogin .authen_txt svg, .mobexLogin .oneucLogin .authen_txt svg path {
  margin-right: 10px;
}
body .login_spinner {
  top: auto !important;
  bottom: 95px;
}
body .signin_spinner .login_spinner {
  bottom: 64px;
}

.search_form{
  color: var(--secondary) !important;
}
body .collapse.showNewCollapse {
  display: block;
}
.comms_color:hover {
  background: #000037 !important;
  color: #fff !important;
}
.comms_color_black:hover {
  background: #262626 !important;
  color: #fff !important;
}
.call_color:hover {
  background: #60A913 !important;
color: #fff !important;
}
.consent_color:hover {
  background: #f6b800 !important;
color: #fff !important;
}


.card button.custom_collapse {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  align-items: center;
  border: none !important;
  border-bottom: 1px solid var(--onsurfacemedium) !important;
  margin: 0 !important;
  background-color: var(--onsurfacelowest);
  height: 80px;
}
.card button.custom_collapse:hover {
  background-color: var(--onsurfacelow);
}

.card button.custom_collapse .cl_rit .arrw_dwn_img {
  transition: ease-in-out 0.5s;
}
div#contacts .card button.custom_collapse[aria-expanded="false"] .cl_rit .arrw_dwn_img {
  transform: rotate(-90deg);
}
.card button.custom_collapse[aria-expanded="true"] ~ .openSharePoint {
  display: block;
}


.register_btn {
  margin-top: 20px;
  color: #fff;
  border-color: #fff;
  margin-left: 15px;
  background: #fcbf00;
  outline: none !important;
  box-shadow: none !important;
}
.register_btn:hover, .register_btn:focus{
  background-color: #f6b800;
  color:#000;
}
.calltowerRegister {
  margin-top: 0;
  color: #fff;
  border-color: #fff;
  margin-left: 15px;
  outline: none !important;
  box-shadow: none !important;
}
.setupRegister {
  margin-top: 0;
  /* color: #EDEDED; */
  border-color: #EDEDED;
  margin-left: 15px;
  outline: none !important;
  box-shadow: none !important;
}
.setupRegister:hover, .setupRegister:focus{
  background-color: #EDEDED;
  color:#000;
}



.calltowerRegister:hover, .calltowerRegister:focus{
  background-color: #2b7e48;
  color:#000;
}
.register_comms{
  margin-top: 0;
  color: #fff;
  border-color: #fff;
  margin-left: 15px;
  background-color: #000045;
  outline: none !important;
  box-shadow: none !important;
}
.register_comms:hover, .register_comms:focus{
  background-color: #000037;
  color:#fff;
}

.setupLoginRegister {
  margin-top: 0;
  color: #fff;
  border-color: #fff;
  margin-left: 15px;
  outline: none !important;
  box-shadow: none !important;
}
.setupLoginRegister:hover, .setupLoginRegister:focus{
  background-color: #D56F00;
  color:#000;
}
/* Simple theme register page css start here */
 
.register_section .step_btn {
  border: 1px solid #fff;
  background: #fcbf00;
  color: #fff;
}
.register_section .step_btn:hover, .register_section .step_btn:focus{
  color: #fff;
}
.register_section .step_active {
  background-color: #fff;
  color: #000 !important;
}
.register_section .step_btn:after {
  background: #fff;
}
.register_section .form-control {
  border-radius: 11px;
  border: 1px solid #e5e5e5;
  background-color:#fff;
}

.register_section .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #999999;
}
.register_section .form-control::-moz-placeholder { /* Firefox 19+ */
  color: #999999;
}
.register_section .form-control:-ms-input-placeholder { /* IE 10+ */
  color: #999999;
}
.register_section .form-control:-moz-placeholder { /* Firefox 18- */
  color: #999999;
}
.register_section .form-control {
  color: #000;
}

.register_section .nxtBtn {
  background-color: #fcbf00;
  min-width: 78px;
  /* margin:37px 0 0 30px; */
  border: none;
  outline: none !important;
  box-shadow: none !important;
  min-width: 74px;
}
.register_section .nxtBtn:hover, .register_section .nxtBtn:focus {
  background-color: #f6b800;
}
.register_section .user_detail {
  background-color: #fff;
}
.register_section label.form-label {
  color: #000;
}
.register_section .next_backbtn a {
  margin: 5px 0 0;
  min-width: auto;
}
.register_section .back_btn {
  background-color: #cccccc;
}
.register_section .back_btn:hover, .register_section .back_btn:focus {
  background-color: #c4c3c3;
}
body .register_section {
  background: #fcbf00;
}
.register_section .term_check .checkmark {
  border: 1px solid #D2D3DA;
}
.register_section .term_check input:checked ~ .checkmark {
  background-color: #fcbf00;
  border-color: #fcbf00;
}
.register_section .next_backbtn a > button {
  min-width: 74px;
}
/* Simple theme register page css end here */


/* calltower theme register page css start here */


.calltowerSection_register .step_btn {
  border: 1px solid #fff;
  background: #3e9138;
  color: #fff;
}
.calltowerSection_register .step_btn:hover, .calltowerSection_register .step_btn:focus{
  color: #fff;
}
.setupRegister .step_btn:hover, .setupRegister .step_btn:focus{
  color: #fff;
}
.calltowerSection_register .step_active {
  background-color: #fff;
  color: #000 !important;
}
.setupRegister .step_active {
  background-color: #fff;
  color: #000 !important;
}
.calltowerSection_register .step_btn:after {
  background: #fff;
}
.calltowerSection_register .form-control {
  border-radius: 11px;
  border: 1px solid #e5e5e5;
  background-color:#fff;
}

.calltowerSection_register .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #999999;
}
.calltowerSection_register .form-control::-moz-placeholder { /* Firefox 19+ */
  color: #999999;
}
.calltowerSection_register .form-control:-ms-input-placeholder { /* IE 10+ */
  color: #999999;
}
.calltowerSection_register .form-control:-moz-placeholder { /* Firefox 18- */
  color: #999999;
}
.calltowerSection_register .form-control {
  color: #000;
}

.calltowerSection_register .nxtBtn {
  background-color: #6bbc15;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  min-width: 74px;
}
.calltowerSection_register .nxtBtn:hover, .calltowerSection_register .nxtBtn:focus {
  background-color: #60A913;
}
.calltowerSection_register .user_detail {
  background-color: #fff;
}
.calltowerSection_register label.form-label {
  color: #000;
}
.calltowerSection_register .next_backbtn a {
  margin: 5px 0 0;
  min-width: auto;
}
.calltowerSection_register .back_btn {
  background-color: #cccccc;
}
.calltowerSection_register .back_btn:hover, .calltowerSection_register .back_btn:focus {
  background-color: #c4c3c3;
}
body .calltowerSection_register {
  background: linear-gradient( 
-560deg
, #6bbc15, #005568) !important;
}
.calltowerSection_register .term_check .checkmark {
  border: 1px solid #D2D3DA;
}
.calltowerSection_register .term_check input:checked ~ .checkmark {
  background-color: #6bbc15;
  border-color: #6bbc15;
}
.calltowerSection_register .next_backbtn a > button {
  min-width: 74px;
}
.calltowerSection_register .next_backbtn a {
  margin: 5px 0 0;
  min-width: auto;
}
.calltowerSection_register .next_backbtn a > button {
  min-width: 74px;
}

/* Register css Onnet */
.calltowerSection_register .nxtBtn {
  background-color: #25C6DA;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  min-width: 74px;
}

.OneNet_register .step_btn {
  border: 1px solid #fff;
  background: #E9E9ED;
  color: #fff;
}
.OneNet_register .step_btn:hover, .calltowerSection_register .step_btn:focus{
  color: #fff;
}
.OneNet_register .step_active {
  background-color: #fff;
  color: #000 !important;
}
.OneNet_register .step_btn:after {
  background: #fff;
}
.OneNet_register .form-control {
  border-radius: 11px;
  border: 1px solid #e5e5e5;
  background-color:#fff;
}

.OneNet_register .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #999999;
}
.OneNet_register .form-control::-moz-placeholder { /* Firefox 19+ */
  color: #999999;
}
.OneNet_register .form-control:-ms-input-placeholder { /* IE 10+ */
  color: #999999;
}
.OneNet_register .form-control:-moz-placeholder { /* Firefox 18- */
  color: #999999;
}
.OneNet_register .form-control {
  color: #000;
}

.OneNet_register .nxtBtn {
  background-color: #BECE31;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  min-width: 74px;
}
.OneNet_register .nxtBtn:hover, .OneNet_register .nxtBtn:focus {
  background-color: #ABB92C;
}
.OneNet_register .user_detail {
  background-color: #fff;
}
.OneNet_register label.form-label {
  color: #000;
}
.OneNet_register .next_backbtn a {
  margin: 5px 0 0;
  min-width: auto;
}
.OneNet_register .back_btn {
  background-color: #cccccc;
}
.OneNet_register .back_btn:hover, .calltowerSection_register .back_btn:focus {
  background-color: #c4c3c3;
}

.OneNet_register .term_check .checkmark {
  border: 1px solid #D2D3DA;
}
.OneNet_register .term_check input:checked ~ .checkmark {
  background-color: #BECE31;
  border-color: #BECE31;
}

.OneNet_register .next_backbtn a > button {
  min-width: 74px;
}

body .OneNet_register{
  background: #E9E9ED;
 
}
/* calltower theme register page css end here */

/* comms theme register page css start here */

.commsSection_register .step_btn {
  border: 1px solid #fff;
  background: #000045;
  color: #fff;
}
.commsSection_register .step_btn:hover, .commsSection_register .step_btn:focus{
  color: #fff;
}
.commsSection_register .step_active {
  background-color: #fff;
  color: #000 !important;
}
.commsSection_register .step_btn:after {
  background: #fff;
}
.commsSection_register .form-control {
  border-radius: 11px;
  border: 1px solid #e5e5e5;
  background-color:#fff;
}

.commsSection_register .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #999999;
}
.commsSection_register .form-control::-moz-placeholder { /* Firefox 19+ */
  color: #999999;
}
.commsSection_register .form-control:-ms-input-placeholder { /* IE 10+ */
  color: #999999;
}
.commsSection_register .form-control:-moz-placeholder { /* Firefox 18- */
  color: #999999;
}
.commsSection_register .form-control {
  color: #000;
}

.commsSection_register .nxtBtn {
  background-color: #000045;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  min-width: 74px;
}
.commsSection_register .nxtBtn:hover, .commsSection_register .nxtBtn:focus {
  background-color: #000037;
}
.commsSection_register .user_detail {
  background-color: #fff;
}
.commsSection_register label.form-label {
  color: #000;
}
.commsSection_register .next_backbtn a {
  margin: 5px 0 0;
  min-width: auto;
}
.commsSection_register .back_btn {
  background-color: #cccccc;
}
.commsSection_register .back_btn:hover, .commsSection_register .back_btn:focus {
  background-color: #c4c3c3;
}
body .commsSection_register {
  background: #000045;
}
.commsSection_register .term_check .checkmark {
  border: 1px solid #D2D3DA;
}
.commsSection_register .term_check input:checked ~ .checkmark {
  background-color: #000045;
  border-color: #000045;
}
.commsSection_register .next_backbtn a > button {
  min-width: 74px;
}

/* comms theme register page css end here */
.register_section, .commsSection_register, .calltowerSection_register,  setupRegister{
  min-height: calc(100vh - 20px);
}
.calltowerSection_register .login_logo {
  max-width: 220px;
}
.disable_btn_nxt{
  background-color:#F2F2F2;
  color: #707070;
  max-width: 74px;
  height: 39px;
  width: 100%;
  border-radius: 11px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 37px 0 0 auto;
  outline: none;
  border: none;
}

.disable_btn_nxt:focus {
  border: none !important;
  outline: none !important;
}
.register_section .blk_btn {
  background-color: #000;
  color: #fff;
}
.register_section .blk_btn:hover {
  background-color:#262626;
}

.blk_btn:focus {
  box-shadow: 0 0 0 3px transparent;
}
.register_cancel_btn{
  min-width: 74px;
  display: flex;
  align-items: flex-end;
  outline:none;
}
.register_cancel_btn button.disable_btn_nxt {
  margin: 37px 0 0 20px;
  min-width: 78px;
}

.register_section .next_backbtn{
  align-items: flex-end;
}
.register_cancel_btn .registerNext{
  margin: 37px 0 0 20px;
}

.register_section .term_check u {
  color: #fcbf00;
  cursor: pointer;
}
.calltowerSection_register .term_check u {
  color: #6bbc15;
  cursor: pointer;
}
.commsSection_register .term_check u {
  color: #1c1cb7;
  cursor: pointer;
}
.mfa_box .terms_login a {
  color: #fcbf00;
}
.user_detail.mfa_box {
  text-align: center;
}
.user_detail.mfa_box .nxtBtn {
  padding: 0px 20px;
  max-width: 110px;
}
.contact_search {
  position: relative;
}
.contact_search svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  cursor: pointer;
}
.srch_bx.contact_search input.form-control {
  background: var(--onsurfacelowest) !important;
}
.login_spinner svg {
  animation: rotation 1s infinite linear;
  width: 47px;
  height: 47px;
}
.credit_span {
  display: flex !important;
  justify-content: space-between;
  margin: 0 -3px !important;
}
.credit_span span {
  font-size: 11px !important;
  padding: 0 3px;
}

/*oneuc */

.Oneuc_register .step_btn {
  border: 1px solid #fff;
  background: #482474;
  color: #FFFFFF;
}
.Oneuc_register .step_btn:hover, .calltowerSection_register .step_btn:focus{
  color: #fff;
} 
.Oneuc_register .step_active {
  background-color: #fff;
  color: #000 !important;
}
.Oneuc_register .step_btn:after {
  background: #fff;
}
.Oneuc_register .form-control {
  border-radius: 11px;
  border: 1px solid #e5e5e5;
  background-color:#fff;
}

.Oneuc_register .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #999999;
}
.Oneuc_register .form-control::-moz-placeholder { /* Firefox 19+ */
  color: #999999;
}
.Oneuc_register .form-control:-ms-input-placeholder { /* IE 10+ */
  color: #999999;
}
.Oneuc_register .form-control:-moz-placeholder { /* Firefox 18- */
  color: #999999;
}
.Oneuc_register .form-control {
  color: #000;
}

.Oneuc_register .nxtBtn {
  background-color: #482474;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  min-width: 74px;
}
.Oneuc_register .nxtBtn:hover, .Oneuc_register .nxtBtn:focus {
  background-color:#66488B;
}
.Oneuc_register .user_detail {
  background-color: #fff;
}
.Oneuc_register label.form-label {
  color: #000;
}
.Oneuc_register .next_backbtn a {
  margin: 5px 0 0;
  min-width: auto;
}
.Oneuc_register .back_btn {
  background-color: #482474;
}
.Oneuc_register .back_btn:hover, .calltowerSection_register .back_btn:focus {
  background-color: #66488B;
}

.Oneuc_register .term_check .checkmark {
  border: 1px solid #D2D3DA;
}
.Oneuc_register .term_check input:checked ~ .checkmark {
  background-color: #BECE31;
  border-color: #BECE31;
}

.Oneuc_register .next_backbtn a > button {
  min-width: 74px;
}

body .Oneuc_register{
  background: #492575;
 
}
.Oneuc_register .blk_btn {
  background-color: #4555ac;
  color: #ffffff;
}
.Oneuc_register .blk_btn:hover, .Oneuc_register .blk_btn:focus {
  background-color: #C5CAE0;
  color: #ffffff;
}
/*end oneuc*/


/* Mobex_register css start*/

.Mobex_register .step_btn {
  border: 1px solid #fff;
  background: #E9E9ED;
  color: #fff;
}
.Mobex_register .step_btn:hover, .calltowerSection_register .step_btn:focus{
  color: #fff;
}
.Mobex_register .step_active {
  background-color: #fff;
  color: #000 !important;
}
.Mobex_register .step_btn:after {
  background: #fff;
}
.Mobex_register .form-control {
  border-radius: 11px;
  border: 1px solid #e5e5e5;
  background-color:#fff;
}

.Mobex_register .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #999999;
}
.Mobex_register .form-control::-moz-placeholder { /* Firefox 19+ */
  color: #999999;
}
.Mobex_register .form-control:-ms-input-placeholder { /* IE 10+ */
  color: #999999;
}
.Mobex_register .form-control:-moz-placeholder { /* Firefox 18- */
  color: #999999;
}
.Mobex_register .form-control {
  color: #000;
}

.Mobex_register .nxtBtn {
  background-color: #BECE31;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  min-width: 74px;
}
.Mobex_register .nxtBtn:hover, .Mobex_register .nxtBtn:focus {
  background-color: #ABB92C;
}
.Mobex_register .user_detail {
  background-color: #fff;
}
.Mobex_register label.form-label {
  color: #000;
}
.Mobex_register .next_backbtn a {
  margin: 5px 0 0;
  min-width: auto;
}
.Mobex_register .back_btn {
  background-color: #cccccc;
}
.Mobex_register .back_btn:hover, .calltowerSection_register .back_btn:focus {
  background-color: #c4c3c3;
}

.Mobex_register .term_check .checkmark {
  border: 1px solid #D2D3DA;
}
.Mobex_register .term_check input:checked ~ .checkmark {
  background-color: #BECE31;
  border-color: #BECE31;
}

.Mobex_register .next_backbtn a > button {
  min-width: 74px;
}

body .Mobex_register{
  background: #E9E9ED;
 
}
.Mobex_register .blk_btn {
  background-color: #4555ac;
  color: #ffffff;
}
.Mobex_register .blk_btn:hover, .Mobex_register .blk_btn:focus {
  background-color: #C5CAE0;
  color: #ffffff;
}


.commsSection_register .blk_btn {
  background-color: #000045;
  color: #ffffff;
}
.commsSection_register .blk_btn:hover, .commsSection_register .blk_btn:focus {
  background-color: #000037;
  color: #ffffff;
}

.calltowerSection_register .blk_btn {
  background-color: #005568;
  color: #ffffff;
}
.calltowerSection_register .blk_btn:hover, .calltowerSection_register .blk_btn:focus {
  background-color: #004D5E;
  color: #ffffff;
}
.OneNet_register .blk_btn {
  background-color: #241F58;
  color: #ffffff;
}
.OneNet_register  .blk_btn:hover, .OneNet_register .blk_btn:focus {
  background-color: #3A3569;
  color: #ffffff;
}
/* Setup Register theme Changes */

.setupRegister .step_btn {
  border: 1px solid #fff;
  background: #ededed;
  color: #fff;
}
.setupRegister .step_btn:hover, .commsSection_register .step_btn:focus{
  color: #fff;
}
.setupRegister .step_active {
  background-color: #fff;
  color: #000 !important;
}
.setupRegister .step_btn:after {
  background: #fff;
}
.setupRegister .form-control {
  border-radius: 11px;
  border: 1px solid #e5e5e5;
  background-color:#fff;
}

.setupRegister .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #999999;
}
.setupRegister .form-control::-moz-placeholder { /* Firefox 19+ */
  color: #999999;
}
.setupRegister .form-control:-ms-input-placeholder { /* IE 10+ */
  color: #999999;
}
.setupRegister .form-control:-moz-placeholder { /* Firefox 18- */
  color: #999999;
}
.setupRegister .form-control {
  color: #000;
}

.setupRegister .nxtBtn {
  background-color:#D56F00;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  min-width: 74px;
}
.setupRegister .nxtBtn:hover, .commsSection_register .nxtBtn:focus {
  background-color: #AA5900;
}
.setupRegister .user_detail {
  background-color: #fff;
}
.setupRegister label.form-label {
  color: #000;
}
.setupRegister .next_backbtn a {
  margin: 5px 0 0;
  min-width: auto;
}
.setupRegister .back_btn {
  background-color: #cccccc;
}
.setupRegister .back_btn:hover, .commsSection_register .back_btn:focus {
  background-color: #c4c3c3;
}
body .setupRegister {
  background: #ededed;
}
.setupRegister .term_check .checkmark {
  border: 1px solid #D2D3DA;
}
.setupRegister .term_check input:checked ~ .checkmark {
  background-color: #D56F00;
  border-color: #ededed;
}
.setupRegister .next_backbtn a > button {
  min-width: 74px;
}
.setupRegister .blk_btn {
  background-color: #000000;
  color: #ffffff;
}
.setupRegister  .blk_btn:hover, .OneNet_register .blk_btn:focus {
  background-color: #333333;
  color: #ffffff;
}


@-webkit-keyframes rotation {
  from {
      -webkit-transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
      -webkit-transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(359deg);
  }
}

.copyDownloadBtns {
  position: fixed;
  left: 30px;
  top: 27px;
  z-index: 9999;
  display: flex;
}
.copyDownloadBtns .copyBtn, .copyDownloadBtns .downloadBtn{
  background: #eee;
  color: #000;
  border-radius: 5px;
  width: 93px;
  display: inline-block;
margin-right:5px;
text-align: center;
cursor: pointer;
}
.copyDownloadBtns .downloadBtn{
width: 75px;
}

.setupLogin .authen_txt {
  bottom: 25px
}
.setupLogin .calltower_txt img {
  margin-right: 0;
}
.setupLogin .authen_txt svg path {
  fill: var(--primary);
}
.setupLogin .authen_txt svg{
  margin-right: 10px;
}
.setupLogin{
  background: #EDEDED !important;

}

.oneucBtn{
  border: 1px solid #fff;
  margin-left: 15px;
}

.mobexBtn{
  border: 1px solid #fff;
    margin-left: 15px;
}
.hidePopup{
  display: none;
}
.refreshPopup {
  position: fixed;
  z-index: 9;
  text-align: center;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  background: rgb(0 0 0 / 20%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.refreshPopup .sure_detail {
  transform: none;
  position: static;
}


@media (max-width: 1440px) {
  .usr_imgcon {
    max-width: 56vw;
  }
  .left_sec .nav-tabs a {
    font-size: 16px;
  }
  .User_pro2 h2 {
    font-size: 21px;
  }
  h1 {
    font-size: 24px;
  }
  .cntct_frm {
    max-width: 663px;
    padding: 0 10px 30px 30px;
  }
}
.welcome_Line{
  max-width: 300px;
  margin: 10px auto 0;
}
.errorSignInLogo {
  max-width: 140px;    
}
.errorSignIntext {
  max-width: 190px;
  margin: -10px auto 0;
  line-height: normal;
}



@media (max-width: 1199px) {
  .calltowerLogin .calltower_txt img, .commsLogin .calltower_txt img, .oneucLogin .calltower_txt img {
    margin-right: 0;
    height: auto !important;
  }
  .calltowerLogin .calltower_txt, .commsLogin .calltower_txt,.oneucLogin .calltower_txt {
      right: 20px;
      bottom: 20px;
      max-width: 210px;
  }
  .calltowerLogin .authen_txt, .commsLogin .authen_txt {
      bottom: 20px;
  }
  .oneNetLogin .calltower_txt img, .commsLogin .calltower_txt img, .mobexLogin .calltower_txt img, .oneucLogin .calltower_txt img {
    margin-right: 0;
    height: auto !important;
  }
  .oneNetLogin .calltower_txt, .commsLogin .calltower_txt, .mobexLogin .calltower_txt, .oneucLogin .calltower_txt{
      right: 20px;
      bottom: 20px;
      max-width: 210px;
  }
  .oneNetLogin .authen_txt, .commsLogin .authen_txt, .mobexLogin .oneucLogin  .authen_txt{
      bottom: 20px;
  }
  .setupLogin .calltower_txt img{
    margin-right: 0;
    height: auto !important;
  }
  .setupLogin .calltower_txt {
      right: 20px;
      bottom: 20px;
      max-width: 210px;
  }
  .setupLogin .authen_txt {
      bottom: 20px;
  }

}
@media (min-width: 992px) {
  .info_sec {
    /* min-width: 200px; */
    max-width: 100%;
  }
}

@media (max-width: 991px) {
  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paper2 {
    transform: translate(0px, 0px) !important;
  }
  /* .info_sec {
		position: absolute;
		left: 100%;
		top:0;
		bottom:0;
		width:100%;
		transition:ease-in-out .5s;
	}
	.info_sec.show {
		width: 100%;
		max-width: 100%;
		height: calc(100vh - 65px);
		left:0px;
	} */
  .cntct_view > .row > div {
    margin-bottom: 40px;
  }
  /*---------- 18 Dec css start here ----------*/
  .MuiDrawer-root.MuiDrawer-docked.Drawer.Drawer-Open {
    left: 100%;
  }
  .MuiDrawer-root.MuiDrawer-docked.Drawer {
    left: 0px !important;
    margin: 0px;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    transition: ease-in-out 0.5s;
    background: #fff;
    z-index: 999;
    max-width: 100% !important;
    background: var(--surface) !important;
    right: auto !important;
  }
  .MuiDrawer-root.MuiDrawer-docked.Drawer.Drawer-Open {
    left: 100% !important;
    right: auto !important;
  }
  .MuiDrawer-root.MuiDrawer-docked.Drawer
    .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paper2 {
    max-width: 100% !important;
  }
  .info_sec {
    margin-left: -1px;
  }
  .calltowerLogin .authen_txt, .commsLogin .authen_txt {
      width: 315px;
      bottom: 15px;
  }
  .calltowerLogin .calltower_txt, .commsLogin .calltower_txt {
    right: 15px;
    bottom: 15px;
    max-width: 185px;
  }
  .oneNetLogin .authen_txt, .commsLogin .authen_txt, .mobexLogin .oneucLogin  .authen_txt{
    width: 315px;
    bottom: 15px;
}
.oneNetLogin .calltower_txt, .commsLogin .calltower_txt,.mobexLogin .calltower_txt, .oneucLogin  .calltower_txt{
  right: 15px;
  bottom: 15px;
  max-width: 185px;
}
.setupLogin .authen_txt {
  width: 315px;
  bottom: 15px;
}
.setupLogin .calltower_txt {
right: 15px;
bottom: 15px;
max-width: 185px;
}

}

@media (min-width: 768px) {
  .bck_arw {
    display: none !important;
  }
  .Mobile_svg {
    display: none !important;
}
}

@media (max-width: 767px) {
  .calltowerLogin .authen_txt, .commsLogin .authen_txt {
    bottom: 15px;
    width: 295px;
    padding: 8px 10px;
    font-size: 15px;
    max-width: 100%;
}
.calltowerLogin .authen_txt svg, .commsLogin .authen_txt svg {
  margin-right: 10px;
  width: 18px;
  height: 21px;
}
.calltowerLogin .calltower_txt, .commsLogin .calltower_txt,.oneucLogin  .calltower_txt {
  right: 0;
  left: 0;
  justify-content: center;
  bottom: 67px;
  max-width: 140px;
}
  .calltowerLogin .inner_loginBox .app_logo, .commsLogin .inner_loginBox .app_logo {
      width: 180px !important;
      /* height: auto !important; */
  }
  .calltowerLogin .inner_loginBox > span.text-center {
    height: 190px;
}
.oneNetLogin .authen_txt svg, .commsLogin .authen_txt svg, .mobexLogin .oneucLogin  .authen_txt svg{
  margin-right: 10px;
  width: 18px;
  height: 21px;
}
.oneNetLogin .calltower_txt, .commsLogin .calltower_txt, .mobexLogin .calltower_txt, .oneucLogin .calltower_txt{
  right: 0;
  left: 0;
  justify-content: center;
  bottom: 67px;
  max-width: 140px;
}
.oneNetLogin .inner_loginBox .app_logo, .commsLogin .inner_loginBox .app_logo,.mobexLogin .oneucLogin .app_logo{
      width: 170px !important;
      /* height: auto !important; */
  }
.oneNetLogin .inner_loginBox > span.text-center {
    height: 190px;
}
body .login_spinner > span {
  font-size: 28px !IMPORTANT;
  width: auto;
  height: auto;
  margin: 0 !important;
}
body .login_spinner {
  top: auto !important;
  bottom: 125px;
}
.calltowerLogin .calltower_txt.signin_calltower_txt, .commsLogin .calltower_txt.signin_calltower_txt, .oneucLogin .calltower_txt.signin_calltower_txt {
  bottom: 15px;
}
.oneNetLogin .calltower_txt.signin_calltower_txt, .commsLogin .calltower_txt.signin_calltower_txt, .mobexLogin .calltower_txt.signin_calltower_txt,.oneucLogin  .calltower_txt.signin_calltower_txt{
  bottom: 15px;
}
body .signin_spinner .login_spinner {
  bottom: 105px;
}

  .newFABMessageButton .ant-btn.messageFAB {
    background: var(--primary) !important;
    color: var(--onPrimary);
    border-color: var(--primary) !important;
    border-radius: 11px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 35%);
    padding: 5px 15px;
    height: 36px;
}
.newFABMessageButton .ant-affix {
  z-index: 0;
}
  .newFABMessageButton{
    display: flex !important;
    color: var(--primary) !important;
  }
  .ant-affix {
    color: var(--primary) !important;
    border-radius: 11px !important;
  }
  
  .left_sec {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
  .drag_btnbox {
    background: #fff;
    height: 90px;
  }
  .right_Sec.show_right_sec {
    left: 0;
    right: 0;
    min-width: 100%;
    transition-delay: 0.8s;
  }
  .msg_txtbx > a,
  label.browse_file a {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 5px;
    border: 1px solid var(--onsurfacelow);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    background: var(--surface);
  }

  .mobileViewScreen .right_Sec {
    position: fixed !important;
    left: 0;
    top: 0;
    bottom: 0;
    transition: ease-in-out 0.8s;
    transition-delay: 0.3s;
    right: 0;
    overflow: hidden;
    width: 100%;
    transform: translateX(100%);
  }
  .msg_txtbx a.ylw_bg,
  .msg_txtbx a.gry_bg {
    margin-right: 0;
    margin-left: 12%;
  }

  /* .MuiDrawer-root.MuiDrawer-docked.Drawer.Drawer-Open {
    display: none;
  } */
  /* .MuiDrawer-root.MuiDrawer-docked.Drawer.Drawer-Open {
    left: 100vw !important;
  } */
  .top_row {
    border-radius: 0px;
  }
  /*--------- 14 sept css start here ---------*/
  .User_pro2 h2 {
    /*font-size: 24px; */
  }
  /* .usr_txt .txt_div > span.icon:hover {
    min-width: 31px;
} */
  .left_sec div#msg_sec ul.usr_list > li {
    transition: ease-in-out 0.5s;
  }
  .mobile_send_btn {
    min-height: 80px;
  }
  .left_sec div#msg_sec ul.usr_list > li:hover {
    /* margin-left: -108px !important;
    padding-right: 85px; */
  }
  li:hover {
    background-color: var(--onsurfacelowest) !important;
  }
  .more_mnuoptn a.dots img {
    width: 29px;
  }
  .more_mnuoptn {
    margin-right: -108px;
    margin-left: 10px;
    position: relative;
    z-index: 9;
  }
  .more_mnuoptn a.dots {
    padding: 2px 0;
    background: var(--secondary);
    border-radius: 0;
    border: none;
    box-shadow: none;
    width: 81px;
    margin-top: -16px;
    height: 80px;
    margin-bottom: -16px;
    white-space: pre-wrap;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
  }
  .left_sec div#msg_sec ul.usr_list > li:hover .more_mnuoptn {
    margin-right: -85px;
  }
  .left_sec {
    min-width: 280px;
    transition: ease-in-out 0.8s;
  }
  .modal-title {
    font-size: 18px;
  }
  .cstm_btn {
    font-size: 14px;
    line-height: 16px;
    padding: 8px 15px;
  }
  .stng_dtls {
    padding: 0;
  }
  .modal {
    z-index: 999999;
  }
  h2 {
    font-size: 20px;
  }
  .badge {
    width: 24px;
    height: 24px;
    font-size: 14px;
  }
  .cntct_frm {
    padding: 0;
  }
  .top_right .crcl_icon {
    margin-left: 5px !important;
  }
  .cntct_hedr {
    margin-bottom: 10px;
  }
  .plus_icon ul.optn_lst a {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .hide_left_sec {
    position: relative;
    margin-left: 0;
  }
  .right_Sec .msg_txtbx {
    border-top: 1px solid var(--onsurfacelow);
    background: var(--surface);
    padding: 39px 15px 15px;
    height: auto !important;
  }
  .MuiDrawer-root.MuiDrawer-docked.Drawer {
    z-index: 99999;
    transition: ease-in-out 0.8s;
  }
  .right_Sec .chat_sec {
    z-index: 99999;
  }

  .right_Sec.show_right_sec {
    left: 0;
    right: 0;
    min-width: 100%;
    transform: translateX(0);
  }
  .right_Sec.show_right_sec {
    transition-delay: 0.5s;
    transition: ease-in-out 0.8s;
  }
  body {
    padding: 0;
  }
  div.ReactTags__tags {
    max-width: calC(100vw - 110px) !important;
  }
  div.ReactTags__selected span.ReactTags__tag.redtag {
    border: 1px solid var(--error) !important;
    color: var(--error) !important;
    background-color: #f4dbdf;
  }
  div.ReactTags__selected span.ReactTags__tag.redtag > button {
    color: var(--error) !important;
  }
  .chat_or_info {
    width: 100%;
  }
  .MuiDrawer-root.MuiDrawer-docked.Drawer.Drawer-Open {
    width: auto;
    right: 0 !important;
  }
  .MuiDrawer-root.MuiDrawer-docked.Drawer.Drawer-Open
    .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paper2 {
    min-width: auto !important;
  }
  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paper2 {
    margin-right: 0px;
    position: absolute;
    left: 0;
    top: 0 !important;
  }
  .icons_msg_txtbx {
    order: 2;
    width: 80%;
    margin-top: 4px;
  }
  .msg_txtbxDiv .srch_input {
    order: 1;
  }
  .msg_txtbxDiv > a {
    order: 3;
    margin-top: 4px;
  }
  .msg_txtbxDiv > .chatOptionsDiv {
    order: 3;
    margin-top: 4px;
    width: auto !important;
    height: auto !important;
  }
  .msg_txtbxDiv textarea.form-control {
    height: 42px !important;
  }
  .mobileViewScreen .chat_bx {
    height: calc(100vh - 145px) !important;
  }
  .chat_bx {
    padding: 15px;
  }
  .alert-pop_up {
    left: 0 !important;
  }

  
.top_row.mobile_topBar {
    flex-wrap: wrap;
}
.topBar_mobile {
  width: auto;
  display: flex;
  align-items: center;
  order: 2;
  border-top: 1px solid var(--onsurfacelow);
  margin: 0px -15px;
  padding: 5px 15px 0;
}
.topBar_mobileDiv {
  order: 2;
}
.mobile_topBar .top_right.d-flex {
  order: 1;
  justify-content: space-between;
  width: 100%;
}
.mobile_topBar div.ReactTags__tags {
  max-width: calC(100vw - 84px) !important;
  width: calC(100vw - 84px) !important;
}
.resize-right-sec .chat_bx_New.chat_bx {
  height: 100% !important;
}

body .spinner {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}
.desktop_svg {
  display: none !important;
}
.Mobile_svg {
  display: block !important;
}
div#contactsPlusDiv {
  z-index: 0;
}
.top_row.mpbilebar {
  justify-content: flex-start;
}
.top_row.mpbilebar .top_left {
  padding-left: 20px;
}
.setupLogin .authen_txt{
  bottom: 15px;
  width: 295px;
  padding: 8px 10px;
  font-size: 15px;
  max-width: 100%;
}
.setupLogin .authen_txt svg {
margin-right: 10px;
width: 18px;
height: 21px;
}
.setupLogin .calltower_txt {
right: 0;
left: 0;
justify-content: center;
bottom: 67px;
max-width: 140px;
}
.setupLogin .inner_loginBox .app_logo{
    width: 180px !important;
    /* height: auto !important; */
}
.setupLogin .inner_loginBox > span.text-center {
  height: 190px;
}
.setupLogin .calltower_txt.signin_calltower_txt{
bottom: 15px;
}


}

/* Contacts + button */

.stng_icon button {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid var(--onsurfacelow);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stng_icon button:after {
  display: none;
}

.stng_icon button:focus {
  border: 1px solid var(--onsurfacemedium);
  background-color: #d9d9d9;
}

.import_contbtn button {
  width: 57px;
  height: 57px;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 19%);
}

.import_contbtn .dropdown-menu:before {
  display: none;
}

.import_contbtn ul.dropdown-menu {
  width: 300px;
  top: -7px !important;
  background: transparent;
  border: none;
  box-shadow: none;
  text-align: right;
  padding: 0;
}

.import_contbtn a.dropdown-item {
  width: max-content !important;
  border: none !important;
  border-bottom: 1px solid #f0f0f0 !important;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.14) !important;
  border-radius: 20px !important;
  background-color: var(--secondary) !important;
  color: var(--surface);
  font-size: 16px;
  font-weight: 400;
  height: 39px;
  padding: 0 20px;
  margin: 10px 0 0 auto;
  right: 0;
}

.import_contbtn .dropdown .dropdown-toggle img {
  transition: 0.3s;
}

.circle.blk_bg {
  transition: transform 0.5s, background-color 0.1s;
}

.circle.blk_bg:hover {
  background-color: var(--secondary-hover) !important;
}

.circle.blk_bg:active {
  background-color: var(--secondary-active) !important;
}

.circle.blk_bg.show {
  transform: rotate(45deg);
}

.stng_icon.plus_icon.import_contbtn {
  right: 25px;
  left: auto;
}

/* Import Csv file */

.drag_main {
  margin: 30px 0px 0 70px;
  max-width: 579px;
}

.drag_contsec {
  height: 341px;
  border-radius: 11px;
  border: 1px dashed var(--onsurfacehigh);
  background-color: rgba(242, 242, 242, 0.25);
  padding: 20px;
  text-align: center;
}

.drag_content {
  max-width: 334px;
  margin: 0 auto;
}

.drag_content h3 {
  color: var(--primary);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.18px;
  line-height: 24px;
  text-align: center;
  color: var(--secondary-text);
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  margin: 30px 0;
}

.drag_content h3 a {
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-decoration: underline;
  color: var(--primary);
}

.drag_content p {
  color: var(--secondary);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: center;
}

.drag_content p a {
  color: var(--primary);
  font-family: Roboto;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-decoration: underline;
}

.drag_btnbox {
  padding: 12px 15px;
  text-align: right;
}

.drag_btn {
  border-radius: 11px;
  display: inline-block;
  text-align: Center;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: center;
  font-family: Roboto;
  padding: 7px 0;
  border: none;
  outline: none !important;
}

.cancel_btn {
  width: 92px;
  height: 39px;
  color: var(--surface) !important;
  background-color: var(--secondary);
}

.next_btn {
  width: auto;
  height: 39px;
  background-color: var(--onsurfacelowest);
  color: var(--onsurfacehigh) !important;
  margin-left: 18px;
  padding: 7px 20px;
}

.file_nm {
  color: var(--secondary-text);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.cont_uploadbx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  border-radius: 11px;
  border: 1px solid var(--onsurfacelow);
  background-color: var(--surface);
  margin-top: 15px;
  padding: 0 15px;
}

.file_icon {
  display: flex;
  align-items: center;
}

.cont_uploadbx .progress {
  height: 8px;
  border-radius: 11px;
}

.cont_uploadbx .progress-bar {
  background-color: var(--primary);
}

.progr_cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;
}

.prog_value {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 20px;
  text-align: right;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  color: var(--onsurfacehigh);
}

.error_msg {
  color: var(--error);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: 0 10px 0 0;
}

.next_yellow {
  background-color: var(--primary);
  color: var(--surface) !important;
}

.import_contbtn button {
  width: 57px;
  height: 57px;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 19%);
}

.import_contbtn .dropdown-menu:before {
  display: none;
}

.import_contbtn ul.dropdown-menu {
  width: 300px;
  top: -7px !important;
  background: transparent;
  border: none;
  box-shadow: none;
  text-align: right;
  padding: 0;
}

.import_contbtn a.dropdown-item {
  width: max-content !important;
  border: none !important;
  border-bottom: 1px solid #f0f0f0 !important;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.14) !important;
  border-radius: 20px !important;
  background-color: var(--secondary) !important;
  color: var(--surface);
  font-size: 16px;
  font-weight: 400;
  height: 39px;
  padding: 0 20px;
  margin: 10px 0 0 auto;
  right: 0;
}

.import_contbtn .dropdown .dropdown-toggle img {
  transition: 0.3s;
}

.import_contbtn .dropdown .dropdown-toggle.show img {
  transform: rotate(45deg);
}

.cust_green {
  background-color: var(--avatars-caribbean-green);
}

.light_orng {
  background-color: var(--avatars-light-orange);
}

.light_blu {
  background-color: var(--avatars-blue);
}

.start_over img {
  margin-right: 5px;
}

.start_over {
  border-bottom: 1px solid #f0f0f0;
  padding: 15px 5px;
  margin-top: 40px !important;
  max-width: 320px;
}

.start_over a {
  font-family: Roboto;
  font-weight: 400;
  color: var(--secondary);
  letter-spacing: 0.14px;
}

.no_marg {
  margin-top: 0px !important;
}

.error_box {
  border-radius: 11px;
  border: 1px solid var(--error);
  background-color: #f4dbdf;
  padding: 13px 20px;
  margin-top: 20px;
}

.error_box p {
  color: var(--error);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.11px;
  line-height: 19px;
  text-align: left;
}

.error_box p a {
  color: var(--error);
  text-decoration: underline;
}

.import_contactbox .add_btn {
  margin-right: 6px;
}

.import_contactbox {
  max-height: calC(100vh - 345px);
  overflow: auto;
}

.contct_upl {
  padding-bottom: 0 !important;
  max-width: 1000px !important;
}

.contct_upl .cntcts_grp {
  padding-right: 15px;
}

.import_contbtn a.dropdown-item img {
  padding-right: 10px;
}

.btn_w_icn img:first-child {
  margin-right: 8px;
}

.btn_w_icn {
  display: flex;
  align-items: center;
}

.ul.optn_lst {
  margin: 0;
  padding: 0;
}

.plus_icon ul.optn_lst a {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  background-color: var(--secondary);
  color: var(--onsecondary);
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  padding: 7px 20px;
  display: inline-block;
  margin-bottom: 14px;
  vertical-align: middle;
  right: auto;
}

.plus_icon ul.optn_lst a img {
  margin-right: 11px;
  display: inline-block;
}

.opened a.circle {
  transform: rotate(45deg);
}

.grp_icn {
  border-radius: 0;
}

.grp_icn span.grp_bx {
  width: 80px !important;
  height: 80px !important;
  min-width: 80px;
  font-size: 35px;
  margin: 0px !important;
}

/* UploadCsv Cancel dialogBox */

.dialogBox {
  z-index: 100000;
  position: fixed;
  background: var(--secondary) 69;
  left: 0;
  right: 0;
  display: block;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.add_groupbox {
  max-height: 240px;
  overflow: auto;
  padding-right: 10px !important;
}

.addcontact_box {
  width: 100%;
  position: fixed;
  background: var(--secondary) 69;
  left: 0;
  right: 0;
  display: block;
  height: 100%;
  top: 0;
}

.addcontact_detail {
  padding: 26px 20px;
  max-width: 312px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.14);
  border-radius: 14px;
  background-color: var(--surface);
  margin: 50px auto 90px;
  position: fixed;
  left: 0;
  right: 0;
  top: 10%;
}

.addcont_txt h3 {
  color: var(--secondary);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 8px;
}

.addcont_txt p {
  color: var(--onsurfacehigh);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 20px;
  text-align: center;
}

.group_check {
  display: block;
  position: relative;
  padding-left: 52px;
  margin-bottom: 27px;
  cursor: pointer;
}

.group_check a {
  color: var(--primary) !important;
  text-decoration: none;
}

.group_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.group_check .checkmark {
  position: absolute;
  top: 0;
  right: 0;
  height: 31px;
  width: 31px;
  border-radius: 50%;
  border: 1px solid var(--onsurfacelow);
}

.group_check input:checked ~ .checkmark {
  background-color: var(--primary);
  border-color: var(--primary);
}

.group_check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.group_check input:checked ~ .checkmark:after {
  display: block;
}

.group_check .checkmark:after {
  left: 10px;
  top: 5px;
  width: 8px;
  height: 16px;
  border: solid white;
  border-width: 0px 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.addgr_btnbox {
  text-align: center;
  padding-top: 25px;
}

.add_grbtn {
  margin-left: 0;
}

.addcontact_box .usr_list li:after {
  content: "";
  display: block;
  background: var(--onsurfacelow);
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.addcontact_box ul.usr_list > li:last-child::after {
  display: block !important;
}

button.pop-closebtn {
  position: absolute;
  top: 10px;
  right: 18px;
  border: none;
  background: transparent;
  outline: none;
}

.share_contact .addcontact_detail {
  max-width: 372px;
  padding: 26px 25px;
}

.choose_inbox h5 {
  color: var(--onsurfacehigh);
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.33px;
  line-height: 16px;
  text-align: left;
  text-transform: uppercase;
}

.add_groupbox {
  max-height: 240px;
  overflow: auto;
  padding-right: 10px !important;
}

.addcontact_box {
  width: 100%;
  position: fixed;
  background: var(--secondary) 69;
  left: 0;
  right: 0;
  display: block;
  height: 100%;
  top: 0;
}

.sure_detail {
  padding: 26px 20px;
  max-width: 312px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.14);
  border-radius: 14px;
  background-color: var(--surface);
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.addcont_txt h3 {
  color: var(--secondary);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 10px;
}

.save_press {
  color: var(--onsurfacehigh);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 16px;
}

.addgr_btnbox {
  text-align: center;
}

.addgr_btnbox button:first-child {
  margin-right: 10px;
}

.del_btn {
  color: var(--error) !important;
  border-radius: 11px;
  border: 1px solid var(--error) !important;
}

.del_btn:hover {
  background: var(--error);
  color: white !important;
}

/* New Contact React tags */

div.ReactTags__tags {
  border-radius: 11px;
  border: 1px solid var(--onsurfacelow);
  background-color: var(--surface);
  color: #000;
  letter-spacing: 0.5px;
  line-height: 24px;
  font-size: 16px;
  height: 41px;
  padding: 4px 10px;
  max-width: 100%;
  position: relative;
  display: block;
  width: 100%;
  border: 0;
  border-color: transparent;
}

div.ReactTags__tags:hover {
  /* color: #000;
    box-shadow: 0 0 0 3px #fddf7f;
    border-color: var(--primary); */
}

.ReactTags__tags > div.ReactTags__selected {
  border: none !important;
  outline: none;
  display: block;
  overflow-y: auto;
  padding-bottom: 0;
  height: 100%;
}

div.ReactTags__tagInput {
  border-radius: 0;
  display: inline-block;
  margin-left: 0 !important;
}

input.ReactTags__tagInputField2 {
  height: 25px;
  margin: 0;
  width: 100%;
  padding: 0;
  color: #000;
  letter-spacing: 0.5px;
  line-height: 24px;
  font-size: 15px;
  border-radius: 11px;
  width: 200px;
  border: 0;
}

input.ReactTags__tagInputField2:focus {
  border: 0;
  outline: none;
}

div.ReactTags__tags {
  position: relative;
  display: block;
  max-width: 100%;
  width: 100%;
  border: 1px solid var(--onsurfacelow);
}

/* hover background change on messages and contacts */

.usr_list > li:hover {
  background-color: var(--onsurfacelowest);
}

.usr_list > li.active {
  background-color: var(--onsurfacelow);
}

/* .usr_list > li:hover {
    background-color: var(--secondary-hover);
    color: var(--onsurfacehigh)!important;
}

.usr_list > li > .usr_txt > h3{
    color: var(--onsurfacehigh)!important;
}

.card-header {
    background: rgba(0, 0, 0, 0.133);
} */

/* Mobile Responsive CSS  */

.mobileViewScreen .top_left h3 {
  max-width: 150px;
  display: block;
}

.mobileViewScreen .left_arrow {
  display: inline-block;
  margin-right: 10px;
}

.mobileViewScreen .info_sec {
  /* max-width: 100%;
    min-width: 100%; */
  /* Flex: 0 0 100%;
    margin-right: -500px;
    height: calc(100vh - 65px);
	border-left: none;
	position: static; */
}

.mobileViewScreen {
  margin: 0px !important;
  border: none !important;
  border-radius: 0 !important;
  height: calc(100vh - 0px) !important;
}

.mobileViewScreen ul.optn_list a img {
  margin-right: 15px;
}

.mobileViewScreen .right_Sec {
  position: static;
}

.mobileViewScreen .top_row {
  border-left: none;
}

.mobileViewScreen .msg_txtbx {
  flex-wrap: wrap;
  height: auto;
  padding-top: 10px;
}

.mobileViewScreen .chatOptionsDiv {
  height: 65px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.chatOptionsDiv a,
label.browse_file a {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid var(--onsurfacelow);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  min-width: 40px;
  background: var(--surface);
}

.mobileViewScreen .chat_bx {
  height: calc(100vh - 180px);
}

.mobileViewScreen .chatOptionsDiv span {
  display: flex;
  align-items: center;
}

.mobileViewScreen .top_row .gry_btn {
  background-color: var(--onsurfacelow);
}

/* Login Loading Screen CSS: */

.login_logo {
  max-width: 320px;
  margin: 0 auto;
}

.userlog_sec {
  background: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  border-radius: 14px;
  border: 1px solid #707070;
}
.calltowerLogin{
  background: linear-gradient(
-560deg
, #6bbc15, #005568) !important;

}
.oneNetLogin{
  background: #E9E9ED !important;

}
.oneucLogin{
  background: #492575 !important;
}
.mobexLogin{
  background: #E5E5E5 !important;
}
.comp_reg {
  color: rgba(255, 255, 255, 0.87);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin: 0;
  padding: 50px 0;
}

.step_btn {
  width: 92px;
  height: 44px;
  border-radius: 12px;
  border: 1px solid var(--onPrimary);
  color: var(--onPrimary);
  background: var(--primary);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 42px;
  display: inline-block;
  align-items: center;
  text-decoration: none;
  position: relative;
}

.step_btn:after {
  content: "";
  width: 95px;
  height: 1px;
  background: var(--onPrimary);
  display: block;
  position: absolute;
  top: 50%;
  left: 90px;
}

.step_btn:last-child:after {
  width: 0;
}

.step_active {
  background-color: var(--onPrimary);
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

.step_btn:not(:last-child) {
  margin-right: 83px;
}

.step_btn:hover {
  color: var(--onPrimary);
}

.step_active:hover {
  color: rgba(0, 0, 0, 0.87);
}

.user_detail {
  padding: 36px 30px;
  height: 100%;
  max-width: 582px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.14);
  border-radius: 14px;
  background-color: var(--onPrimary);
  margin: 50px auto 0;
}

.user_detail .form-control {
  padding-left: 15px;
}

.user_head {
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.18px;
  line-height: 24px;
  padding-bottom: 14px;
}

.nxtBtn {
  max-width: 74px;
  width: 100%;
  height: 39px;
  border-radius: 11px;
  background-color: var(--primary);
  text-align: center;
  color: #fff !important;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 37px 0 0 auto;
  outline: none;
}

.nxtBtn:hover {
  background-color: #f5b800;
  color: #fff;
}

.nxtBtn:active {
  background-color: #ebb000;
  color: #fff;
}

.nxtBtn:focus {
  background-color: var(--primary);
  color: #fff;
  box-shadow: 0 0 0 3px var(--primary-shadow);
}

.country_select {
  position: relative;
}

.country_select:after {
  content: "";
  background: url(./images/down_arrow.svg) no-repeat;
  width: 15px;
  height: 10px;
  position: absolute;
  right: 12px;
  background-size: contain;
  background-position: center center;
  top: 15px;
}

.country_select select {
  color: var(--onsurfacehigh);
  padding-right: 30px;
}

.next_backbtn {
  display: flex;
  justify-content: space-between;
}

.next_backbtn a {
  margin: 5px 0 0;
}

.back_btn {
  background-color: #cccccc;
}

.back_btn:hover {
  background-color: #c4c3c3;
  color: #fff;
  outline: none;
}

.back_btn:active {
  background-color: #aaaaaa;
  outline: none;
  color: #fff;
}

.back_btn:focus {
  box-shadow: 0 0 0 3px rgba(204, 204, 204, 0.5);
  background-color: #cccccc;
  color: #fff;
}

/*register/login  detail  page css end  */

/*login term page css start */

.terms_login {
  padding: 25px 0 10px;
}

.terms_login label:first-child {
  margin-bottom: 25px;
}

.term_check {
  display: block;
  position: relative;
  padding-left: 52px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}

.term_check a {
  color: var(--primary) !important;
  text-decoration: none;
}

.term_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.term_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 31px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid var(--onsurfacelow);
}

.term_check input:checked ~ .checkmark {
  background-color: var(--primary);
  border-color: var(--primary);
}

.term_check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.term_check input:checked ~ .checkmark:after {
  display: block;
}

.term_check .checkmark:after {
  left: 9px;
  top: 5px;
  width: 8px;
  height: 16px;
  border: solid white;
  border-width: 0px 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*login term page css end */

/*login error page css start */

.error_sec {
  height: calc(100vh - 20px);
  padding: 10px 0;
  position: relative;
}

.error_logo {
  max-width: 150px;
  margin: 0 auto;
}
.logoLogin_div {
  max-width: 270px;
}

.error_detail {
  max-width: 312px;
  padding: 23px 20px;
}

.logout_btn {
  margin: 0 auto;
  max-width: 93px;
}

.error_detail h5 {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 24px;
}

.error_detail p {
  color: var(--onsurfacehigh);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
}

/*login error page css end */

.vrsn_txt {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: center;
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
}

.authen_txt {
  padding: 10px;
  border-radius: 14px;
  background-color: var(--surface);
  text-align: center;
  max-width: 85vw;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 24px;
  position: absolute;
  left: 0;
  width: 387px;
  right: 0;
  margin: auto;
  bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calltower_txt{
  background-color: "none"!important;
  text-align: center;
  max-width: 85vw;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 24px;
  position: absolute;
  left: 0;
  width: 387px;
  right: 0;
  margin: auto;
  bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 14px;
}
.calltower_txt img{
  margin-right: 10px;
}

.authen_txt img {
  margin-right: 10px;
}

/* ChatPanel Drawer */

.panel_menu {
  width: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  font-weight: bold;
  height: 10%;
  text-align: center;
  align-content: center;
}

/* .panel_menu:hover {
  background-color: antiquewhite;
  color: var(--primary);
} */

.avtar {
  color: #fff !important;
  background-color: var(--primary) !important;
}

.alings_tab {
  display: flex;
  flex-direction: row;
}

.name_tab {
  padding-left: 5px;
  padding-top: 4px;
}

.divider {
  background-color: black !important;
}

.MuiDrawer-paper {
  top: 76px !important;
  flex: 1 0 auto;
  /* height: 86.5% !important; */
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  bottom: 72px !important;
  border-bottom-right-radius: 0%;
  /* border: 1px solid; */
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  width: 100%;
  margin-right: 11px;
}

.MuiDrawer-paper2 {
  /* height: calC(100% - 88px)!important; */
  width: auto !important;
  min-width: 200px !important;
  max-width: 480px !important;
}

.new-message-cnt {
  display: flex !important;
  align-items: center;
}

.padding-icon {
  padding-right: 8px;
}

.div-span-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.div-span-cont > h3 {
  overflow: hidden;
  max-width: 135px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.send-msg-btn-cont {
  margin-left: auto;
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 10px;
}

.btn-sends {
  background-color: var(--primary) !important;
  color: var(--onPrimary) !important;
  border-radius: 10.5px !important;
  padding-right: 5px !important;
  outline: none !important;
  height: 39px !important;
  width: 166px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all 0.235s !important;
  text-transform: unset !important;
  font-size: 16px !important;
}
.btn-sends:hover,
.btn-group-focus:hover {
  background: var(--primary-hover) !important;
}
.btn-sends:active,
.btn-group-focus:active {
  background: var(--primary-active) !important;
}
.btn-sends:focus,
.btn-group-focus:focus {
  background: var(--primary-active) !important;
}
.btn-send-focus {
  background-color: var(--primary) !important;
  color: var(--onPrimary) !important;
  border-radius: 10.5px !important;
  padding-right: 5px !important;
  outline: none !important;
  height: 39px !important;
  width: 166px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all 0.235s !important;
  text-transform: unset !important;
  font-size: 16px !important;
}
.btn-send-focus:hover,
.btn-send-group:hover {
  box-shadow: 0 0 0 3px var(--primary-shadow);
}

.new_div {
  height: 60px;
  width: "100%";
}
.cntct_info.cnvrt_actn.sendmsg li svg {
  width: 50px;
  text-align: center;
}

.group_onbox {
  min-height: 40px;
  background-color: var(--surface);
  color: var(--text);
  border: 1px solid var(--onsurfacelow);
}

.togl_btn {
  border: 0;
  background-color: var(--onsurfacelow);
  border-color: var(--onsurfacelow);
}

.togl_btn:hover {
  background-color: var(--onsurfacemedium);
  border-color: var(--onsurfacemedium);
}

.togl_btn:active {
  background-color: var(--onsurfacehigh);
  border-color: var(--onsurfacehigh);
}

.outln_btn {
  background-color: var(--surface);
  border-color: var(--onsurfacelow);
}

.outln_btn:hover {
  background-color: var(--onsurfacelow);
  border-color: var(--onsurfacemedium);
}

.outln_btn:active {
  background-color: var(--onsurfacemedium);
  border-color: var(--onsurfacehigh);
}

/*------ 23 dec css ----------*/

.stng_icon .dropdown-toggle.show {
  background-color: #d9d9d9;
  border-color: #d9d9d9 !important;
}
.stng_icon .dropdown-toggle.show:hover {
  box-shadow: 0 0 0 3px rgb(0 0 0 / 15%);
  border-color: var(--onsurfacemedium) !important;
  background: #fff !important;
}
.stng_icon .dropdown-toggle:focus {
  background-color: transparent;
  border: 1px solid var(--onsurfacelow);
}

.new-emoji-picker {
  position: absolute;
  bottom: 60px;
  left: 0px;
  background-color: var(--onsurfacehigh);
  border-color: var(--onsurfacehigh);
}

.InboxDropdown > li {
  color: var(--text);
}

div.ReactTags__tags3 {
  min-height: 41px;
}

a#contacts_tab {
  border-left: transparent;
  border-radius: 0;
}

a#contacts_tab.active {
  border-left: 1px solid var(--onsurfacelow) !important;
  border-radius: 10px 0 0 0;
}

a#msg_tab {
  border-left: none;
  border-right: 1px solid rgb(0 0 0 / 0%);
  border-radius: 0;
}

.left_sec .nav-tabs a#msg_tab.active {
  border-right: 1px solid var(--onsurfacelow) !important;
  border-radius: 0 10px 0 0;
}

a#msg_tab:hover {
  border-right-color: rgb(0 0 0 / 0%) !important;
}
.chatImage {
  position: relative;
  margin-bottom: 5px;

}
.chatImage svg {
    position: absolute;
    left: 40px;
    top: -5px;
    background: #f9f9f9;
    padding: 3px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
}
.ant-select-selection-placeholder {
  font-size: 16px;
}
.default_screen {
  border-left: 1px solid var(--onsurfacelow);
}

.ant-dropdown {
  z-index: 99999 !important;
}

/* new css 4-jan */

.alert-pop_up {
  top: 66px;
  max-width: "100vw";
  height: 40px;
}
/* 4 jan 2021 css*/
.default_contact {
  position: relative;
}
.default_contact:after {
  content: "";
  position: absolute;
  background-image: url(/static/media/down_arrow.6b2400ee.svg);
  bottom: 14px;
  right: 14px;
  width: 18px;
  height: 11px;
}
.default_contact select {
  padding-right: 36px;
}
.emoji-focus {
  box-shadow: 0 0 0 3px rgb(0 0 0 / 15%);
  border-color: #d9d9d9 !important;
  background: #d9d9d9 !important;
}
.msg_txtbx .icons_msg_txtbx > a.emoji-focus:hover {
  box-shadow: 0 0 0 3px rgb(0 0 0 / 15%);
  border-color: var(--onsurfacemedium) !important;
  background: #fff !important;
}
.btn-transform {
  text-transform: capitalize !important;
}
.usr_list li:hover .rit_bx span.badge{
  transform: translateX(15px) !important;
}
#msg_sec .User_pro.User_pro2 .rit_bx.ritbox_tr span.badge {
  transform: translateX(-0);
}
.info_grp .usr_img2 {
  bottom: 0px;
  left: 0;
}
.info_grp .usr_img22 {
  left: 5px;
}
.usr_txt .txt_div.group_recipt{
  color: var(--text);
}
.inner_loginBox{
  background: linear-gradient( 
    -560deg
     ,#6bbc15,#005568)!important;
}
.plus_icon ul.optn_lst a:active{
  background-color: var(--secondary-active) !important;
}
.plus_icon ul.optn_lst a:hover{
  background-color: var(--secondary-hover) !important;
}

@media (min-width: 992px) and (max-width: 1100px) {
  h2 {
    font-size: 18px;
  }
  ul.optn_list a {
    line-height: 16px;
    font-size: 16px;
  }
  ul.optn_list a svg {
    width: 30px !important;
    margin-right: 5px;
  }
}

@media (min-width: 992px) and (max-width: 1140px) {
  .form-email-field {
    word-break: break-all;
  }
  .usr_imgcon {
    max-width: 50vw;
  }
}

@media (max-width: 540px) {
  .plus_icon {
    padding: 0px 0;
  }
  .chat_bx {
    padding: 15px;
  }
  .drag_main {
    margin: 30px 6px 0 10px;
    max-width: 570px;
  }
  .new-emoji-picker {
    position: absolute;
    bottom: 25px;
    left: 0px;
  }
  ul.dropdown-menu:before {
    display: inline-block;
    content: "";
    border-bottom: 9px solid;
    border-right: 5px solid transparent;
    border-top: 0;
    border-left: 5px solid transparent;
    color: var(--onsurfacelow-text);
    position: absolute;
    top: -9px;
    right: 25px;
  }
  ul.dropdown-menu {
    left: auto;
    right: 0;
  }
  .drag_btnbox {
    padding: 12px 15px;
    text-align: right;
    margin-top: 7%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .msg_txtbx a.ylw_bg,
  .msg_txtbx a.gry_bg {
    margin-right: 0;
    margin-left: 9%;
  }
}
@media (max-width: 400px) {
  .msg_txtbx a.ylw_bg,
  .msg_txtbx a.gry_bg {
    margin-right: 0;
    margin-left: 5%;
  }
}

@media (max-width:370px){
  .calltowerLogin .inner_loginBox .app_logo {
      width: 200px !important;
      height: 190px !important;
  }
  .oneNetLogin .inner_loginBox .app_logo, .mobexLogin .oneucLogin .inner_loginBox .app_logo {
    width: 150px !important;
    height: auto !important;
}
  
  body .login_spinner > span {
      font-size: 26px !IMPORTANT;
  }
  body .login_spinner {
      bottom: 120px;
  }
  .calltowerLogin .calltower_txt, .commsLogin .calltower_txt, .oneucLogin .calltower_txt {
    bottom: 61px;
    max-width: 135px;
}
  .calltowerLogin .authen_txt, .commsLogin .authen_txt {
      bottom: 12px;
      padding: 8px 10px;
      font-size: 14px;
  }
  .calltowerLogin .authen_txt svg, .commsLogin .authen_txt svg {
      margin-right: 8px;
  }
  .oneNetLogin .authen_txt, .commsLogin .authen_txt, .mobexLogin .oneucLogin .authen_txt{
      bottom: 12px;
      padding: 8px 10px;
      font-size: 14px;
  }
  .oneNetLogin .authen_txt svg, .commsLogin .authen_txt svg, .mobexLogin .oneucLogin .authen_txt svg{
      margin-right: 8px;
  }
  .oneNetLogin .calltower_txt, .commsLogin .calltower_txt, .mobexLogin .calltower_txt, .oneucLogin .calltower_txt{
    bottom: 61px;
    max-width: 135px;
}
.setupLogin .inner_loginBox .app_logo {
  width: 200px !important;
  height: 190px !important;
}
.setupLogin .calltower_txt{
bottom: 61px;
max-width: 135px;
}
.setupLogin .authen_txt {
  bottom: 12px;
  padding: 8px 10px;
  font-size: 14px;
}
.setupLogin .authen_txt svg{
  margin-right: 8px;
}

}

@media (max-width: 310px) {
  .msg_txtbx a.ylw_bg,
  .msg_txtbx a.gry_bg {
    margin-right: 0;
    margin-left: 2%;
  }
}
@media (min-width: 765px) {
  #newMessage-backArrow {
    display: none;
  }
}

@media (max-width: 768px) {
  .drag_main {
    margin: 30px 0 0 0px;
    max-width: 579px;
  }
  ul.dropdown-menu:before {
    display: inline-block;
    content: "";
    border-bottom: 9px solid;
    border-right: 5px solid transparent;
    border-top: 0;
    border-left: 5px solid transparent;
    color: var(--onsurfacelow-text);
    position: absolute;
    top: -9px;
    right: 25px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .crcl_icon {
    height: 40px;
    width: 40px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px !important;
  }
  .contct_upl .row {
    display: grid;
    box-sizing: border-box;
  }
}

@media (max-width: 375px) {
  .top_row .usr_txt h3 {
    padding-left: 2px;
    width: 80px;
  }
}
@media ( max-height: 500px ){
.call-tower-logo{
  top: 40%;
  width: 280px; 
}
.calltowerLogin .inner_loginBox .app_logo, .mobexLogin .oneucLogin .inner_loginBox .app_logo{
  height: 190px !important;
  width: auto !important;
}
.calltower_txt, .commsLogin .calltower_txt, .mobexLogin .calltower_txt, .oneucLogin .calltower_txt{
    bottom: 61px;
    max-width: 135px;
}
.oneNetLogin .inner_loginBox .app_logo {
  height: 190px !important;
  width: auto !important;
}
.oneNetLogin .commsLogin .calltower_txt {
  bottom: 61px;
  max-width: 135px;
}

body .signin_spinner .login_spinner {
  bottom: 19px;
}
.setupLogin .inner_loginBox .app_logo{
  height: 190px !important;
  width: auto !important;
}

}

@media (max-width: 1440px) {
  /* .autocomplete-dropdown.autocomplete-dropdown-ContactOpen {
    max-width: 623px!important;
  } */
}

.group_heading {
  white-space: pre-wrap;
  text-align: left;
  padding: 5px;
  word-break: break-word;
}

.number_txt_div {
  font-size: 13px;
}
